// Dynamic module route
import { routeGuard } from './route.guard'

export default [
  {
    path: '/users-teams',
    name: 'users-teams',
    meta: {
      requiresAuth: true,
      linkText: 'users-teams',
      imgSrc: '/img/baseline_supervised_user_circle_white_48dp.png'
    },
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "users-teams" */ './UsersTeams.vue')
  }
]

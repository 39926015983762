// Dynamic module route
import { beforeController } from './route.guard'

export default [
  {
    path: '/controller',
    name: 'controller',
    meta: {
      requiresAuth: true,
      linkText: 'controller',
      imgSrc: '/img/baseline_analytics_white_48dp.png'
    },
    beforeEnter: beforeController,
    component: () => import(/* webpackChunkName: "controller" */ './Controller.vue')
  },
  {
    path: '/controller/details',
    name: 'details',
    meta: {
      requiresAuth: true,
      linkText: 'details',
      imgSrc: '/img/baseline_analytics_white_48dp.png'
    },
    beforeEnter: beforeController,
    component: () => import(/* webpackChunkName: "controller" */ './Details.vue')
  }
]

import {
  GET_OAUTH_FACEBOOK_LINK,
  SAVE_OAUTH_FACEBOOK_TOKEN,
  FACEBOOK_LOGOUT,
  GET_OAUTH_TWITTER_LINK,
  SAVE_OAUTH_TWITTER_TOKEN,
  TWITTER_LOGOUT
} from '@/core/action-types'
import {
  SET_OAUTH_FACEBOOK_LINK,
  SET_OAUTH_FACEBOOK_TOKEN,
  SET_OAUTH_TWITTER_LINK,
  SET_OAUTH_TWITTER_TOKEN,
  ADD_LOADING,
  REMOVE_LOADING,
  SET_USER_DATA,
  RESET_USER_FB_TOKEN,
  RESET_USER_TW_TOKEN
} from '@/core/mutation-types'
import {
  HTTP_OAUTH_FACEBOOK,
  HTTP_OAUTH_FACEBOOK_CALLBACK,
  HTTP_OAUTH_FACEBOOK_LOGOUT,
  HTTP_OAUTH_TWITTER,
  HTTP_OAUTH_TWITTER_CALLBACK,
  HTTP_OAUTH_TWITTER_LOGOUT
} from '@/core/http-common'
import {
  notifyError,
  notifySuccess
} from '@/core/store'

export default {
  state: {
    oauthFacebookLink: null,
    oauthFacebookToken: null,
    oauthTwitterLink: null,
    oauthTwitterToken: null
  },
  mutations: {
    [SET_OAUTH_FACEBOOK_LINK] (state, payload) {
      state.oauthFacebookLink = payload.data.url
    },
    [SET_OAUTH_FACEBOOK_TOKEN] (state, payload) {
      state.oauthFacebookToken = payload
    },
    [SET_OAUTH_TWITTER_LINK] (state, payload) {
      state.oauthTwitterLink = payload.data.url
    },
    [SET_OAUTH_TWITTER_TOKEN] (state, payload) {
      state.oauthTwitterToken = payload
    }
  },
  actions: {
    async [GET_OAUTH_FACEBOOK_LINK] ({ commit, state, rootState }) {
      commit(ADD_LOADING, GET_OAUTH_FACEBOOK_LINK)
      HTTP_OAUTH_FACEBOOK().get('/')
        .then(response => {
          commit(REMOVE_LOADING, GET_OAUTH_FACEBOOK_LINK)
          commit(SET_OAUTH_FACEBOOK_LINK, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_OAUTH_FACEBOOK_LINK)
          notifyError(error, commit)
        })
    },
    async [SAVE_OAUTH_FACEBOOK_TOKEN] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_OAUTH_FACEBOOK_TOKEN)
      HTTP_OAUTH_FACEBOOK_CALLBACK().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_OAUTH_FACEBOOK_TOKEN)
          // TODO: Update only the token!!!
          commit(SET_OAUTH_FACEBOOK_TOKEN, response.data)
          commit(SET_USER_DATA, response.data)
          notifySuccess('Facebook connected', commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_OAUTH_FACEBOOK_TOKEN)
          notifyError(error, commit)
        })
    },
    async [FACEBOOK_LOGOUT] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, FACEBOOK_LOGOUT)
      HTTP_OAUTH_FACEBOOK_LOGOUT().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, FACEBOOK_LOGOUT)
          // TODO: Update only the token!!!
          commit(SET_OAUTH_FACEBOOK_TOKEN, null)
          commit(RESET_USER_FB_TOKEN)
          notifySuccess('Facebook disconnected', commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, FACEBOOK_LOGOUT)
          console.log(error)
          notifyError(error, commit)
        })
    },
    async [GET_OAUTH_TWITTER_LINK] ({ commit, state, rootState }) {
      commit(ADD_LOADING, GET_OAUTH_TWITTER_LINK)
      HTTP_OAUTH_TWITTER().get('/')
        .then(response => {
          commit(REMOVE_LOADING, GET_OAUTH_TWITTER_LINK)
          commit(SET_OAUTH_TWITTER_LINK, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_OAUTH_TWITTER_LINK)
          notifyError(error, commit)
        })
    },
    async [SAVE_OAUTH_TWITTER_TOKEN] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_OAUTH_TWITTER_TOKEN)
      HTTP_OAUTH_TWITTER_CALLBACK().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_OAUTH_TWITTER_TOKEN)
          // TODO: Update only the token!!!
          commit(SET_OAUTH_TWITTER_TOKEN, response.data)
          commit(SET_USER_DATA, response.data)
          notifySuccess('Twitter connected', commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_OAUTH_TWITTER_TOKEN)
          notifyError(error, commit)
        })
    },
    async [TWITTER_LOGOUT] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, TWITTER_LOGOUT)
      HTTP_OAUTH_TWITTER_LOGOUT().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, TWITTER_LOGOUT)
          // TODO: Update only the token!!!
          commit(SET_OAUTH_TWITTER_TOKEN, null)
          commit(RESET_USER_TW_TOKEN)
          notifySuccess('Twitter disconnected', commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, TWITTER_LOGOUT)
          notifyError(error, commit)
        })
    }
  },
  getters: {
    oauthFacebookLink: state => state.oauthFacebookLink,
    oauthFacebookToken: state => state.oauthFacebookToken,
    oauthTwitterLink: state => state.oauthTwitterLink,
    oauthTwitterToken: state => state.oauthTwitterToken
  }
}

import Vue from 'vue'
import App from './core/App.vue'
import router from './core/router'
import store from './core/store'
import './registerServiceWorker'
import i18n from './plugins/i18n'
// import initFacebookSdk from './plugins/fbSdk'
import FlagIcon from 'vue-flag-icon'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.config.productionTip = false
Vue.use(FlagIcon)
Vue.use(VueTour)

// wait for facebook sdk to start app
// initFacebookSdk().then(startApp)

// TODO: Create config file (apis, endpoints, environment vars...)
// function startApp () {
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('.app-wrapper')
// }

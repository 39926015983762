import {
  GET_TEMPLATES,
  SAVE_NEW_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  SEARCH_ORDERS
} from '@/core/action-types'
import {
  ADD_LOADING,
  REMOVE_LOADING,
  SET_TEMPLATES,
  AD_TEMPLATE,
  SET_TEMPLATE,
  REMOVE_TEMPLATE,
  SET_FOUND_ORDERS
} from '@/core/mutation-types'
import {
  HTTP_REPORTING_TEMPLATES,
  HTTP_ORDERS_SEARCH
} from '@/core/http-common'
import {
  notifyError,
  notifySuccess
} from '@/core/store'

export default {
  state: {
    templates: [],
    foundOrders: []
  },
  mutations: {
    [SET_TEMPLATES] (state, payload) {
      state.templates = payload.data
    },
    [AD_TEMPLATE] (state, payload) {
      state.templates.push(payload.data)
    },
    [SET_TEMPLATE] (state, payload) {
      state.templates[state.templates.findIndex(item => item._id === payload.data._id)] = payload.data
      state.templates = [...state.templates]
    },
    [REMOVE_TEMPLATE] (state, payload) {
      state.templates.splice(state.templates.findIndex(item => item._id === payload.id), 1)
      state.templates = [...state.templates]
    }
  },
  actions: {
    async [GET_TEMPLATES] ({ commit, state, rootState }) {
      commit(ADD_LOADING, GET_TEMPLATES)
      HTTP_REPORTING_TEMPLATES().get('/', { params: { limit: 1000 } })
        .then(response => {
          commit(REMOVE_LOADING, GET_TEMPLATES)
          commit(SET_TEMPLATES, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_TEMPLATES)
          notifyError(error, commit)
        })
    },
    async [SAVE_NEW_TEMPLATE] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_NEW_TEMPLATE)
      HTTP_REPORTING_TEMPLATES().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_TEMPLATE)
          commit(AD_TEMPLATE, response.data)
          notifySuccess('Template created', commit)
          dispatch(GET_TEMPLATES)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_TEMPLATE)
          notifyError(error, commit)
        })
    },
    async [UPDATE_TEMPLATE] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPDATE_TEMPLATE)
      const queryID = query.id
      delete query.id
      HTTP_REPORTING_TEMPLATES().put(`/${queryID}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_TEMPLATE)
          commit(SET_TEMPLATE, response.data)
          notifySuccess('Template updated', commit)
          dispatch(GET_TEMPLATES)
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_TEMPLATE)
          notifyError(error, commit)
        })
    },
    async [DELETE_TEMPLATE] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_TEMPLATE)
      const queryID = query.id
      delete query.id
      HTTP_REPORTING_TEMPLATES().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_TEMPLATE)
          commit(REMOVE_TEMPLATE, query)
          notifySuccess('Template deleted', commit)
          dispatch(GET_TEMPLATES)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_TEMPLATE)
          notifyError(error, commit)
        })
    },
    // TODO: PORQUÉ HAY AQUÍ UN SEARCH_ORDERS??? PARA QUÉ SIRVE???
    async [SEARCH_ORDERS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, SEARCH_ORDERS)
      HTTP_ORDERS_SEARCH().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SEARCH_ORDERS)
          commit(SET_FOUND_ORDERS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SEARCH_ORDERS)
          notifyError(error, commit)
        })
    }
  },
  getters: {
    templates: state => state.templates
  }
}

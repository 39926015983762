import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=14cc765e&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=14cc765e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14cc765e",
  null
  
)

/* custom blocks */
import block0 from "@/core/i18n-common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fhtml%2Ffrontend%2Fsrc%2Fcore%2Fcomponents%2FModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports
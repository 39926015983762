<template>
  <div ref="app-wrapper" class="app-wrapper"
    :class="[ !authData ? 'app-wrapper--login' : '', loading.length > 0 ? 'app-wrapper--is-loading' : '' ]">
    <!-- , navMenuCollapsed ? 'app-wrapper--collapsed-menu' : 'app-wrapper--expanded-menu' -->
    <div class="modal-veil" v-if="modalVeil"></div>
    <modal v-if="modalData" :modalData="modalData" ref="modal-data"></modal>
    <v-tour name="welcomeTour" :steps="tourSteps" :options="{ highlight: true }"></v-tour>
    <div class="platform-switcher">
      <a class="platform-switcher__link" href="https://adbibo.turboadv.com/" target="_blank">{{ $t('programmatic') }}</a>
      <router-link to="/" class="platform-switcher__link platform-switcher__link--selected" exact>{{ $t('social-media') }}</router-link>
    </div>
    <nav-menu
      class="nav-menu"
      :alerts="['manager']"
      :auth="authData"></nav-menu>
    <header class="header">
      <div class="header__left">
        <router-link to="/dashboard/agencies" class="header__app-link" exact>
          <img class="header__app-logo-1" src="/img/84x84-PredActive-Next14.png" alt="Adbibo Tech">
          <img class="header__app-logo-2" src="/img/232x84-PredActive-Next14.png" alt="Adbibo Tech">
        </router-link>
      </div>
      <p class="header__middle">{{ now }}</p>
      <div class="header__right" v-if="authData">
        <button-with-menu v-for="(button, index) in menuConfig" :key="index" :config="button"></button-with-menu>
      </div>
    </header>
    <transition name="fade">
      <router-view class="main" />
    </transition>
    <notification-bar v-if="notificationData" :notification="notificationData"></notification-bar>
  </div>
</template>
<script>
// team.role: admin / agency, client, adops-team, commercial-team, billing-team
// Para creación de elementos en la plataforma:
// authData.team.tokens.facebook === null
// authData.team.tokens.twitter === null
import {
  mapGetters,
  mapActions
} from 'vuex'
import NavMenu from '@/core/components/NavMenu.vue'
import Modal from '@/core/components/Modal.vue'
import NotificationBar from '@/core/components/NotificationBar'
import {
  GET_USER_DATA
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
import welcomeTourSteps from './welcomeTourSteps.json'
import ButtonWithMenu from './components/ButtonWithMenu.vue'
export default {
  name: 'app',
  components: {
    Modal,
    NotificationBar,
    NavMenu,
    ButtonWithMenu
  },
  data: function () {
    return {
      userNotifications: [],
      viewWithMargins: true,
      menuConfig: [
        {
          title: this.$t('notifications'),
          userNotifications: this.userNotificationsLength,
          icon: `/img/baseline_notifications${this.userNotificationsLength > 0 ? '_active' : ''}_white_48dp.png`
        },
        {
          title: this.$t('settings'),
          icon: '/img/baseline_settings_white_48dp.png',
          isHidden: true,
          children: [
            {
              title: this.$t('users-teams'),
              route: '/users-teams',
              icon: '/img/baseline_supervised_user_circle_white_48dp.png'
            }
          ]
        },
        {
          title: this.$t('help'),
          icon: '/img/baseline_help_white_48dp.png'
        },
        {
          title: this.$t('user-menu'),
          id: 'user-menu',
          icon: '/img/baseline_account_circle_white_48dp.png',
          children: [
            {
              title: this.$t('user-profile'),
              route: '/profile',
              icon: '/img/baseline_face_white_48dp.png'
            },
            {
              title: this.$t('sign-out'),
              route: '/signout',
              icon: '/img/baseline_exit_to_app_white_48dp.png'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'authData',
      'appPath',
      'notificationData',
      'modalData',
      'modalVeil'
    ]),
    now () {
      return new Date().toLocaleString(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    },
    tourSteps () {
      return welcomeTourSteps[i18n.locale]
    },
    userNotificationsLength () {
      return this.userNotifications?.length || 0
    }
  },
  created: function () {
    const defaultLanguage = localStorage.getItem('defaultLanguage')

    if (defaultLanguage) {
      i18n.locale = defaultLanguage
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.authData === null) {
        if (localStorage.getItem('access_token') !== null) {
          this.fetchData()
          // TODO: REVISAR VTOUR!!!
          // this.$tours['welcomeTour'].start()
        } else if (window.location.pathname.indexOf('/password/reset') < 0) {
          this.$router.push({ path: '/signin' })
        }
      }
    })
  },
  watch: {
    authData: function (newValue, oldValue) {
      if (newValue === null) {
        this.$router.push({ path: '/signin' })
      } else {
        if (this.$route.path === '/' || this.$route.path === '/signin') {
          this.$router.push({ path: '/dashboard/agencies' })
        } else {
          const SETTINGS_IDX = 1
          this.menuConfig = this.menuConfig.map((menuItem, idx) => (
            {
              ...menuItem,
              isHidden: idx === SETTINGS_IDX && newValue.role !== 'admin'
            }
          )
          )
        }
      }
    }
    // ,
    // appPath: function (newValue, oldValue) {
    //   console.log('appPath', location.href)
    //   console.log('CHANGE appPath', newValue, oldValue)
    // }
  },
  methods: {
    ...mapActions({
      fetchData: GET_USER_DATA
    }),
    showHideNav () {
      this.navMenuVisible = !this.navMenuVisible
    }
  }
}
</script>
<style scoped>
.app-wrapper {
  display: grid;
  grid-template-rows: 18px 64px 1fr;
  grid-template-columns: 64px 1fr;
  height: 100vh;
  transition: all ease .3s;
}
.nav-menu {
  grid-row: 2 / -1;
}
.modal-veil {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.28);
  padding: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-content: center;
}
.header {
  font-size: .75rem;
  font-weight: 200;
  letter-spacing: 1px;
  color: var(--app-font-color);
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  justify-content: space-between;
  grid-row: 2;
  grid-column: 2 / -1;
  padding: 0;
  background-color: var(--app-white);
  z-index: 100;
}
.platform-switcher {
  grid-row: 1;
  grid-column: 1 / -1;
}
.platform-switcher,
.header__top {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  background-color: var(--header-app-link);
  font-size: 12px;
  line-height: 18px;
}
.platform-switcher__link,
.header__top__link {
  color: var(--card-subtitle-font-color);
  padding: 0 10px 0 0;
  line-height: 18px;
}
.platform-switcher__link:hover,
.header__top__link:hover {
  color: var(--app-dark-blue);
}
.platform-switcher__link--selected,
.header__top__link--selected {
  font-weight: bold;
  color: white;
}
.header__right {
  padding-right: 10px;
}
.header__middle {
  padding: 0 20px;
}
.header__middle,
.header__left,
.header__right {
  display: flex;
  align-items: center;
  line-height: 64px;
  height: 64px;
}
.header__em {
  font-style: normal;
  color: var(--nav-link-image-color);
}
.header__app-logo-1,
.header__app-logo-2 {
  cursor: pointer;
  height: 60px;
}
.header__white-rabbit-logo {
  background-color: var(--app-white-rabbit-background-color);
}
.header__app-link {
  color: var(--header-app-link);
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 20px;
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
}
.header__app-link-subtitle {
  font-size: 10px;
  flex-basis: 100%;
  line-height: 10px;
  font-style: normal;
}
.header__app-link:hover {
  color: rgb(157, 170, 197);
}
.hidden-menu {
  display: flex;
  height: 64px;
}
.hidden-menu__item {
  position: relative;
  display: flex;
  flex-basis: 400px;
}
.hidden-menu__item--secondary {
  margin-left: -300px;
  min-width: 300px;
}
.hidden-menu.--hover .hidden-menu__item--secondary,
.hidden-menu:hover .hidden-menu__item--secondary {
  margin-left: 0;
}
.main {
  grid-row: 3;
  grid-column: 1 / -1;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-perspective: 1px;
  perspective: 1px;
  -ms-scroll-snap-type: block proximity;
  scroll-snap-type: block proximity;
  -ms-scroll-snap-destination: 0% 100%;
  scroll-snap-destination: 0% 100%;
  -ms-scroll-snap-points-y: repeat(100%);
  scroll-snap-points-y: repeat(100%);
}
.app-wrapper--login {
  grid-template-columns: 1fr;
}
.app-wrapper--login .nav-menu {
  display: none;
}
.app-wrapper--login .header,
.app-wrapper--login .main,
.app-wrapper--login .platform-switcher {
  grid-column: 1;
}
@media screen and (min-width: 1024px) {
  .main {
    grid-column: 2 / -1;
  }
}
.app-wrapper--is-loading .main {
  opacity: 0.5;
}
.app-wrapper--is-loading::before {
  content: '';
  position: fixed;
  /* top: 78px; */
  left: 0px;
  top: 17px;
  z-index: 100;
  width: 100vw;
  height: 5px;
  background: linear-gradient(270deg, var(--app-dark-blue), var(--app-light-blue), var(--app-white));
  background-size: 600% 600%;
  -webkit-animation: isLoading 4s ease infinite;
  -moz-animation: isLoading 4s ease infinite;
  animation: isLoading 4s ease infinite;
  z-index: 10000;
}
</style>
<i18n src="./i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "settings": "Settings",
    "notifications": "Notifications",
    "user-menu": "User menu",
    "campaign-requests": "Campaign Requests",
    "tags": "Tags",
    "users-teams": "Users and Teams",
    "options": "Options",
    "close-campaigns": "Close Campaigns",
    "access-log": "Access Log",
    "user-profile": "User profile",
    "sign-out": "Sign Out"
  },
  "es": {
    "settings": "Ajustes",
    "notifications": "Notificaciones",
    "user-menu": "Menú de usuario",
    "campaign-requests": "Solicitudes de campaña",
    "tags": "Etiquetas",
    "users": "Usuarios",
    "users-teams": "Usuarios y equipos",
    "options": "Opciones",
    "close-campaigns": "Cerrar campañas",
    "access-log": "Registro de acceso",
    "user-profile": "Perfil de usuario",
    "sign-out": "Desconectar"
  }
}
</i18n>

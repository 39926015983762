// Dynamic module route
import {
  beforeSignIn,
  beforeSignOut,
  beforePasswordReset
  // ,
  // beforeRouteLeave
} from './route.guard'

export default [
  {
    path: '/signin',
    alias: '/',
    name: 'signin',
    meta: {
      linkText: 'signin',
      imgSrc: '/img/baseline_exit_to_app_white_48dp.png'
    },
    beforeEnter: beforeSignIn,
    component: () => import(/* webpackChunkName: "signin" */ './SignIn.vue')
  },
  {
    path: '/signout',
    name: 'signout',
    meta: {
      requiresAuth: true,
      linkText: 'signout',
      imgSrc: '/img/baseline_enter_to_app_white_48dp.png'
    },
    beforeEnter: beforeSignOut,
    component: () => import(/* webpackChunkName: "signout" */ './SignOut.vue')
  },
  {
    path: '/password/reset',
    name: 'passwordreset',
    meta: {
      linkText: 'passwordreset',
      imgSrc: '/img/baseline_enter_to_app_white_48dp.png'
    },
    beforeEnter: beforePasswordReset,
    component: () => import(/* webpackChunkName: "passwordreset" */ './PasswordReset.vue')
  }
]

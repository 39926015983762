import {
  GET_REPORT,
  GET_REPORTS,
  DELETE_REPORT,
  HIDE_REPORT,
  SEARCH_ORDERS,
  DOWNLOAD_REPORT
} from '@/core/action-types'
import {
  ADD_LOADING,
  REMOVE_LOADING,
  REMOVE_REPORT,
  SET_REPORT,
  SET_REPORTS,
  SET_FOUND_ORDERS
} from '@/core/mutation-types'
import {
  HTTP_REPORTING_REPORTS,
  HTTP_ORDERS_SEARCH
} from '@/core/http-common'
import {
  notifyError,
  notifySuccess
} from '@/core/store'

export default {
  state: {
    reportData: [],
    foundOrders: [],
    reports: []
  },
  mutations: {
    [SET_REPORT] (state, payload) {
      state.reportData = payload.data
    },
    [SET_REPORTS] (state, payload) {
      state.reports = [...payload.data]
    },
    [REMOVE_REPORT] (state, payload) {
      state.reports.splice(state.reports.findIndex(item => item._id === payload.id), 1)
      state.reports = [...state.reports]
    },
    [SET_FOUND_ORDERS] (state, payload) {
      state.foundEntities = payload.data
    }
  },
  actions: {
    async [GET_REPORT] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_REPORT)
      HTTP_REPORTING_REPORTS().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, GET_REPORT)
          commit(SET_REPORT, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_REPORT)
          notifyError(error, commit)
        })
    },
    async [GET_REPORTS] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_REPORTS)
      HTTP_REPORTING_REPORTS().get('/', { params: { limit: 1000 } })
        .then(response => {
          commit(REMOVE_LOADING, GET_REPORTS)
          commit(SET_REPORTS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_REPORTS)
          notifyError(error, commit)
        })
    },
    async [HIDE_REPORT] ({ dispatch, commit, state, rootState }, query) {
      commit(SET_REPORT, { data: null })
    },
    async [DELETE_REPORT] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_REPORT)
      const queryID = query.id
      HTTP_REPORTING_REPORTS().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_REPORT)
          commit(REMOVE_REPORT, query)
          notifySuccess('Report deleted', commit)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_REPORT)
          notifyError(error, commit)
        })
    },
    // TODO: PORQUÉ HAY AQUÍ UN SEARCH_ORDERS??? PARA QUÉ SIRVE???
    async [SEARCH_ORDERS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, SEARCH_ORDERS)
      HTTP_ORDERS_SEARCH().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SEARCH_ORDERS)
          commit(SET_FOUND_ORDERS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SEARCH_ORDERS)
          notifyError(error, commit)
        })
    },
    async [DOWNLOAD_REPORT] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, DOWNLOAD_REPORT)
      HTTP_REPORTING_REPORTS().get(query.url, { responseType: 'blob', forceUpdate: true })
        .then(response => {
          commit(REMOVE_LOADING, DOWNLOAD_REPORT)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, DOWNLOAD_REPORT)
          notifyError(error, commit)
        })
    }
  },
  getters: {
    reports: state => state.reports,
    reportData: state => state.reportData,
    foundEntities: state => state.foundEntities
  }
}

<template>
  <fieldset v-if="fielddata.invisible === undefined || fielddata.invisible === false || !fielddata.invisible"
    class="form__field"
    :class="[ fielddata.dataList || fielddata.type === 'taglist' ? 'form__field--multivalue' : '', fielddata.class ? fielddata.class : '', !fielddata.label ? 'form__field--no-label' : '', fielddata.type === 'grouped-checkbox-list' ? 'grouped-checkbox-list-wrapper' : '']">
    <textarea v-if="fielddata.type === 'textarea'"
      class="input input__text input__textarea"
      :class="[ fielddata.inputClass ]"
      v-model="fieldValue"
      :title="fielddata.title"
      :id="fielddata.name"
      :required="fielddata.required"
      :maxlength="fielddata.maxlength"
      :readonly="fielddata.readonly"
      :disabled="fielddata.disabled"
      v-on:input="onChange">
    </textarea>
      <!-- :class="[ isEmpty ? 'input--empty' : '', fielddata.inputClass, fielddata.value ]" -->
      <!-- :pattern="fielddata.type === 'number' ? '[0-9]*[,.]?[0-9]+' : null" -->
      <!-- :type="fielddata.type === 'number' ? 'text' : fielddata.type" -->
    <input v-else-if="fielddata.type === 'text' || fielddata.type === 'password' || fielddata.type === 'email' || fielddata.type === 'number' || fielddata.type === 'search'"
      ref="input"
      class="input input__text"
      :class="[ isEmpty ? 'input--empty' : '', fielddata.type === 'number' ? '--ta-r' : '', fielddata.inputClass ]"
      :type="fielddata.type"
      v-model="fieldValue"
      :title="fielddata.title"
      :id="fielddata.name"
      :required="fielddata.required"
      :pattern="fielddata.type === 'number' ? '[0-9]+([\.,][0-9]+)?' : null"
      :min="fielddata.type === 'number' && fielddata.min ? fielddata.min : null"
      :max="fielddata.type === 'number' && fielddata.max ? fielddata.max : null"
      :inputmode="fielddata.type === 'number' ? 'decimal' : 'text'"
      :step="fielddata.step"
      :maxlength="fielddata.maxlength"
      :minlength="fielddata.minlength"
      :readonly="fielddata.readonly"
      :disabled="fielddata.disabled"
      autocomplete="new-password"
      v-on:input="onChange"
      v-on:keydown.enter="onKeyDownEnter" />
    <input v-else-if="fielddata.type === 'date' || fielddata.type === 'datetime-local'"
      ref="input"
      class="input input__date"
      :class="[ fielddata.name === 'start_date' ? 'input__date--from' : 'input__date--to', fielddata.inputClass ]"
      :type="fielddata.type"
      v-model="fieldValue"
      :title="fielddata.title"
      :id="fielddata.name"
      :required="fielddata.required"
      :maxlength="fielddata.maxlength"
      :readonly="fielddata.readonly"
      :disabled="fielddata.disabled"
      autocomplete="new-password"
      v-on:input="onChange"
      v-on:keydown.enter="onKeyDownEnter" />
    <label v-if="fielddata.label"
      :for="fielddata.name"
      class="input__label"
      :class="[fielddata.type === 'radio' || fielddata.type === 'checkbox' || fielddata.type === 'select' || fielddata.type === 'taglist' ? 'input__label__multivalue' : '', fielddata.type === 'select' && fielddata.required && fieldValue ==='' ? 'input__date__label--invalid' : '']">
      {{ fielddata.label }}
    </label>
    <template v-if="fielddata.type === 'select'">
      <select class="input input__select"
        :class="[ fielddata.multiple ? 'input__select--multiple' : '', fielddata.inputClass ]"
        :id="fielddata.name"
        v-model="fieldValue"
        v-on:change="fielddata.multiple ? onChangeMultiSelect($event) : onChange($event)"
        v-on:keydown.enter="onKeyDownEnter"
        :size="fielddata.multiple ? 5 : null"
        :multiple="fielddata.multiple"
        :readonly="fielddata.readonly"
        :disabled="fielddata.disabled"
        :required="fielddata.required">
        <option value="" v-if="!fielddata.multiple && !fielddata.required"></option>
        <option value="" v-if="fielddata.required" disabled selected>{{ $t('please-choose') }}</option>
        <option
          v-for="(dataItem, index) in fielddata.dataList"
          :value="dataItem.value"
          :selected="fieldValue === dataItem.value"
          :disabled="(fielddata.readonly && fieldValue !== dataItem.value) || dataItem.disabled"
          :key="index">{{ dataItem.label }}</option>
      </select>
    </template>
    <template v-else-if="fielddata.type === 'radio'">
      <template v-for="(dataItem, index) in fielddata.dataList">
        <input
          class="input__radio"
          :class="[ dataItem.value === fieldValue ? 'input__radio--checked' : '', fielddata.inputClass ]"
          :type="fielddata.type"
          v-model="fieldValue"
          :value="dataItem.value"
          :id="`${fielddata.name}-${index}`"
          :key="`${fielddata.name}-${index}`"
          :name="fielddata.name"
          :title="dataItem.label"
          :disabled="fielddata.disabled"
          v-on:change="onChangeMultiValue"
          v-on:keydown.enter="onKeyDownEnter"
          />
        <label
          class="input__label input__label--radio input__label__singlevalue"
          :key="index"
          :for="`${fielddata.name}-${index}`">{{ dataItem.label }}</label>
      </template>
    </template>
    <template v-else-if="fielddata.type === 'checkbox'">
      <template v-for="(dataItem, index) in fielddata.dataList">
        <input
          class="input__checkbox"
          :class="[ fieldValue.includes(dataItem.value) ? 'input__checkbox--checked' : '', fielddata.inputClass ]"
          :type="fielddata.type"
          v-model="fieldValue"
          :checked="fieldValue.includes(dataItem.value)"
          :value="dataItem.value"
          :id="`${fielddata.name}-${index}`"
          :key="`${fielddata.name}-${index}`"
          :name="fielddata.name"
          :title="dataItem.label"
          :disabled="fielddata.disabled"
          v-on:change="onChangeMultiValueCheckbox"
          v-on:keydown.enter="onKeyDownEnter"
          />
        <label
          class="input__label input__label--checkbox input__label__singlevalue"
          :key="index"
          :for="`${fielddata.name}-${index}`">{{ dataItem.label }}</label>
      </template>
    </template>
    <template v-else-if="fielddata.type === 'taglist'">
      <p
        class="tags-list"
        :class="fielddata.inputClass">
        <!-- <a v-for="(data, index) in fielddata.value" :key="index" href="#" class="tag tag--green tag--with-close-icon tag--with-margin" :title="$t('clear')" @click.prevent="clearTag(data)">{{ data.label }}</a> -->
        <a v-for="(data, index) in fielddata.value" :key="index" href="#" class="tag tag--with-margin" :title="$t('clear')" @click.prevent="clearTag(data)">
          {{ data.label }}
          <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')">
        </a>
      </p>
      <input type="text"
        class="input__fake"
        v-model="fieldValue"
        :id="fielddata.name"
        :name="fielddata.name"
        :required="fielddata.required" />
    </template>
    <template v-else-if="fielddata.type === 'grouped-checkbox-list'">
      <div class="grouped-checkbox-list">
        <ol class="content-column" v-for="(group, index) in dataListGroups" :key="index">
          <!-- <li class="column-row column-row-category">{{ $t(group) }}</li> -->
          <li class="column-row column-row-category">
            <input
              class="input__checkbox"
              :class="[ fieldGroups.includes(group) ? 'input__checkbox--checked' : '', fielddata.inputClass ]"
              type="checkbox"
              v-model="fieldGroups"
              :checked="fieldGroups.includes(group)"
              :value="group"
              :id="`${fielddata.name}-${group}`"
              name="groups"
              :title="`${$t(group)}`"
              :disabled="fielddata.disabled">
            <label
              class="input__label input__label--checkbox input__label__singlevalue"
              :for="`${fielddata.name}-${group}`">{{ $t(group) }}</label>
          </li>
          <li :key="dataItemIndex" class="column-row" v-for="(dataItem, dataItemIndex) in fielddata.dataList.filter(item => item.group === group)">
            <input
              class="input__checkbox"
              :class="[ fieldValue.includes(dataItem.value) ? 'input__checkbox--checked' : '', fielddata.inputClass ]"
              type="checkbox"
              v-model="fieldValue"
              :checked="fieldValue.includes(dataItem.value)"
              :value="dataItem.value"
              :id="`${fielddata.name}-${group}-${dataItemIndex}`"
              :key="`${fielddata.name}-${group}-${dataItemIndex}`"
              :name="fielddata.name"
              :title="`${$t(dataItem.label)}`"
              :disabled="fielddata.disabled"
              v-on:change="onChangeMultiValueCheckbox"
              v-on:keydown.enter="onKeyDownEnter">
            <label
              class="input__label input__label--checkbox input__label__singlevalue"
              :for="`${fielddata.name}-${group}-${dataItemIndex}`">{{ $t(dataItem.label) }}</label>
          </li>
        </ol>
      </div>
    </template>
  </fieldset>
</template>
<script>
// TODO: Show Password icon
export default {
  name: 'form-field',
  props: [
    'fielddata'
  ],
  data: function () {
    return {
      fieldGroups: [],
      fieldValue: (['select', 'checkbox', 'taglist', 'grouped-checkbox-list'].includes(this.fielddata.type)) ? [] : ''
    }
  },
  computed: {
    isEmpty () {
      return this.fielddata.value === '' &&
        (this.fieldValue === '' ||
          (Array.isArray(this.fieldValue) && this.fieldValue.length === 0))
    },
    fieldComputedValue: {
      get () {
        const decimals = (this.fielddata.step) ? this.countDecimals(this.fielddata.step) : -1
        return (decimals >= 0)
          ? parseFloat(this.fieldValue === '' ? 0 : this.fieldValue).toFixed(decimals)
          : (this.fielddata.type === 'date' && this.fieldValue !== '') ? this.toDateInputValue(this.fieldValue) : this.fieldValue
      },
      set (newValue) {
        this.fieldValue = newValue
      }
    },
    dataListGroups () {
      let returnValue = []
      if (this.fielddata.type === 'grouped-checkbox-list') {
        returnValue = new Set(this.fielddata.dataList.map(item => item.group))
      }
      return returnValue
    }
  },
  watch: {
    fieldGroups (newValue, oldValue) {
      const removedGroups = oldValue.filter(item => !newValue.includes(item))
      const removedItems = this.fielddata.dataList.filter(item => removedGroups.includes(item.group)).map(item => item.value)

      this.fieldValue = [
        ...this.fieldValue.filter(item => !removedItems.includes(item)),
        ...this.fielddata.dataList.filter(item => newValue.includes(item.group)).map(item => item.value)
      ]
      this.$emit('change', {
        field: this.fielddata.name,
        value: this.fieldValue
      })
    },
    // Just in case I need to further check for other deep changes:
    // fielddata: {
    //   handler (newValue, oldValue) {
    //     this.fieldValue = newValue.value
    //   },
    //   deep: true
    // },
    'fielddata.value': function (newValue, oldValue) {
      this.fieldValue = newValue
    }
  },
  mounted () {
    this.fieldValue = this.fielddata.value
  },
  methods: {
    onKeyDownEnter (e) {
      e.stopPropagation()
      e.preventDefault()
      this.onChange(e)
    },
    onChange (e) {
      this.fieldValue = e.target.value
      this.$emit('change', {
        field: this.fielddata.name,
        value: this.fieldValue
      })
    },
    onChangeMultiSelect (e) {
      this.fieldValue = Array.apply(null, e.target.selectedOptions).map(option => option.value)
      this.$emit('change', {
        field: this.fielddata.name,
        value: this.fieldValue
      })
    },
    onChangeMultiValue (e) {
      this.fieldValue = e.target.value
      this.$emit('change', {
        field: this.fielddata.name,
        value: this.fieldValue
      })
    },
    onChangeMultiValueCheckbox (e) {
      this.$emit('change', {
        field: this.fielddata.name,
        value: this.fieldValue
      })
    },
    clearTag (data) {
      this.fieldValue.splice(this.fielddata.value.findIndex(item => item.value === data.value), 1)
      this.$emit('change', {
        field: this.fielddata.name,
        value: this.fieldValue
      })
    },
    // Date format methods
    countDecimals (value) {
      if (Math.floor(value) === value) {
        return 0
      }
      return value.toString().split('.')[1].length || 0
    },
    toDateInputValue (value) {
      let local = new Date(value)
      local.setMinutes(local.getMinutes() - local.getTimezoneOffset())
      return local.toJSON().slice(0, 10)
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
.form__field {
  position: relative;
  padding: 0 0 7px;
  margin: 20px 0 0 0;
  border: none;
}
/* .form__field--multivalue {
  margin: 0;
} */
.form__field--no-label {
  margin-top: 0;
}
.input {
  border-width: 0;
  /* background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2); */
  /* background-size: 0 2px, 100% 1px; */
  /* background-repeat: no-repeat; */
  /* background-position: center bottom, center calc(100% - 1px); */
  /* background-color: transparent; */
  box-shadow: none;
  /* border-radius: 0; */
  color: var(--app-font-color);
  float: none;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  height: 36px;
  line-height: var(--app-line-height);
  margin: 0 0 7px;
  padding: 7px 0;
  /* transition: background 0s ease-out; */
  width: 100%;
  outline: none;
}
.input:disabled {
  color: var(--app-disabled-color);
  cursor: default;
  /* background-color: rgba(235, 235, 228, 0.3); */
}
.input:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-color: #e8f0fe !important; */
  background-color: var(--app-light-blue) !important;
  /* background-image: none !important; */
  /* color: -internal-light-dark(black, white) !important; */
}
.input__text {
  padding: 7px 20px;
}
.input__date {
  padding: 7px 0px 7px 20px;
}
.--percent-input > .input__text {
    background-image: linear-gradient(black, black), url('/img/baseline_percent_white_48dp.png');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 13px);
    background-position-y: 50%;
    background-size: 20px;
    background-blend-mode: difference;
    padding-right: 40px;
}
/* .input__date {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
  background-size: 0 2px, 100% 1px, 24px 24px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px), calc(100% - 3px) 3px;
  background-color: transparent;
}
.input__date--from {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
}
.input__date--to {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_event_black_48dp.png');
}
.input__text:focus {
  outline: none;
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
} */
.input__label {
  color: var(--app-disabled-color);
  cursor: text;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  line-height: var(--input-line-height);
  margin: 10px 0 0;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  top: -7px;
  left: 20px;
  will-change: left, top, contents;
}
.input__date ~ .input__label,
.input__text:focus ~ .input__label,
.input__text:valid:not(.input--empty) ~ .input__label,
.input__text:-webkit-autofill ~ .input__label,
.input__text:read-only ~ .input__label,
.input__text:disabled ~ .input__label,
.input__label--focus,
.input__label__multivalue,
.input__label--invalid,
.input__text--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  top: -30px;
  left: 20px;
  font-size: 11px;
  line-height: 1;
}
.input__label--invalid,
/* .input__text--invalid, */
.input__date__label--invalid,
/* .input__text:invalid, */
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  color: var(--app-dark-red);
}
/* .input__label__multivalue {
  margin: 10px 0 0;
  position: relative;
  display: block;
  top: unset;
  font-size: 11px;
  line-height: 1;
} */
.input__label__singlevalue {
  /* margin: 0 10px 10px 0; */
  margin: 5px 10px 10px 2px;
  border: none;
  border-radius: 4px;
  /* background-color: var(--card-background-color);
  background-image: linear-gradient(black, black), url('/img/baseline_radio_button_unchecked_white_48dp.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
  background-size: 25px;
  background-blend-mode: difference; */
  line-height: var(--input-line-height);
  padding: 0px 10px 0px 25px;
  position: relative;
  display: inline-block;
  top: unset;
  pointer-events: unset;
  cursor: pointer;
  color: black;
}
/* .input__label__singlevalue.input__label--checkbox {
  background-image: linear-gradient(black, black), url('/img/baseline_check_box_outline_blank_white_48dp.png');
} */
.input__radio--checked + .input__label__singlevalue.input__label--radio,
.input__checkbox--checked + .input__label__singlevalue.input__label--checkbox {
  /* background-image: linear-gradient(black, black), url('/img/baseline_check_box_white_48dp.png'); */
  color: var(--app-dark-blue);
}
.input__label__singlevalue:hover {
    outline: none;
    box-shadow: var(--app-shadow-color);
}
.input__radio {
  display: none;
}
/* .input__radio--checked + .input__label__singlevalue,
.input__radio[checked] + .input__label__singlevalue {
    background-image: linear-gradient(black, black), url('/img/baseline_radio_button_checked_white_48dp.png');
} */
.input__radio--disabled + .input__label__singlevalue:hover {
    box-shadow: none;
    cursor: default;
}
.input__select--multiple {
  height: 60px;
  padding: 0;
}
.input__textarea,
.input__select--multiple--large {
  height: 120px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: auto;
  text-indent: 0;
  background-image: none;
}
.tags-list {
  margin-top: 5px;
}
.tags-list--w100 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 125px;
  overflow: auto;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.tag {
  max-width: calc(100% - 40px);
  position: relative;
  padding-right: 40px;
}
.tag .btn__img {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
}
.input__fake {
  opacity: 0;
  width: 0;
  height: 0;
}
option {
  padding: 5px 20px;
}
option:checked {
  background-color: var(--app-dark-blue) !important;
  color: var(--app-white) !important;
}
.grouped-checkbox-list-wrapper {
  overflow-y: scroll;
  max-height: 300px;
}
.grouped-checkbox-list {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "top-row top-row top-row"
    "col1 col2 col3";
  gap: 20px;
  padding: 20px 0 20px 20px;
}
.content-column {
  /* height: 100px; */
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.column-row {
  margin-bottom: 5px;
}
.column-row .input__label {
  margin: 0;
  position: relative;
  top: unset;
  left: unset;
}
.column-row-category {
  border-bottom: 1px solid var(--app-light-blue);
}
.column-row-category .input__label {
  font-weight: var(--app-font-bold);
}
</style>

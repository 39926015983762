<template>
  <nav class="nav">
    <ul class="nav__menu">
      <!-- v-for="route in $router.options.routes"  -->
      <li class="nav__item" v-for="(item, index) in navItems" :key="index">
        <router-link :to="{ path: item.path, query: $route.query }" v-slot="{ href, route, navigate  }">
          <!-- :class="[ isActive || $route.path.indexOf(route.name) >= 0 ? 'nav__link--active' : '' ]" -->
          <a class="nav__link"
            :href="href"
            :class="[ $route.name && $route.name.indexOf(item.name) >= 0 ? 'nav__link--active' : '', alerts.includes(item.name) ? 'nav__link--alert' : '' ]"
            :data-scroll-to="`${route.name}-view`"
            @click="navigate">
            <div class="nav__link__wrapper">
              <div class="nav__link__icon">
                <img :src="alerts && alerts.includes(route.name) ? route.meta.alertImgSrc : route.meta.imgSrc" alt="" class="nav__link__icon__img">
                <div v-if="alerts && alerts.includes(route.name)" class="btn__badge">1</div>
              </div>
              <div class="nav__link__text">
                {{ $t(route.meta.linkText) }}
              </div>
            </div>
          </a>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'nav-menu',
  props: [
    'auth',
    'alerts'
  ],
  data: function () {
    return {
      isCollapsed: true
    }
  },
  computed: {
    navItems () {
      let navOptions = []
      if (this.auth !== undefined && this.auth !== null) {
        if (this.auth.role === 'admin' || this.auth.team.permissions.find(item => item.name === 'order.show' || item.name === 'campaign.show')) {
          navOptions.push({ name: 'dashboard', path: '/dashboard/agencies' })
        }
        if (this.auth.role === 'admin' || this.auth.team.permissions.find(item => item.name === 'campaign.create')) {
          navOptions.push({ name: 'campaign', path: '/campaign' })
        }
        if (this.auth.role === 'admin' || this.auth.team.permissions.find(item => item.name === 'controller.create')) {
          navOptions.push({ name: 'controller', path: '/controller' })
        }
        if (this.auth.role === 'admin' || this.auth.team.permissions.find(item => item.name === 'report.create')) {
          navOptions.push({ name: 'reports', path: '/reports' })
        }
        if (this.auth.role === 'admin' || this.auth.team.permissions.find(item => item.name === 'template.create')) {
          navOptions.push({ name: 'templates', path: '/templates' })
        }
      } else {
        navOptions.push({ name: 'signin', path: '/signin' })
      }
      return navOptions
    }
  }
  // ,
  // methods: {
  //   toggleMenu () {
  //     this.isCollapsed = !this.isCollapsed
  //     this.$emit('on-nav-menu-toggled', { navMenuCollapsed: this.isCollapsed })
  //   }
  // }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
.nav {
  /* display: none; */
  background-color: var(--app-dark-blue);
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  padding-top: 82px;
}
.nav__menu,
.nav__item {
  background-color: inherit;
}
.nav__item {
  padding-top: 10px;
}
.nav__link {
  display: block;
  cursor: pointer;
  color: var(--app-white);
  background-color: inherit;
}
.nav__link__wrapper {
  display: flex;
  position: relative;
  align-items: center;
  background-color: inherit;
  flex-direction: column;
}
.nav__link__icon {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin: 1px auto;
  overflow: hidden;
}
.nav__link__icon__img {
  display: block;
  width: 30px;
  height: 30px;
  margin: 5px;
  -o-object-position: center center;
  object-position: center center;
  -o-object-fit: cover;
  object-fit: cover;
  fill: var(--app-white);
  filter: var(--app-png-white-filter);
}
.nav__link__text {
  margin: 5px 0 0;
  color: var(--nav-link-active-color);
  text-align: center;
  white-space: nowrap;
  font-size: 9px;
}
.nav__link--active .nav__link__icon {
  background-color: var(--app-ochre);
}
.nav__link--active .nav__link__icon__img {
  fill: var(--app-dark-grey);
  filter: var(--app-png-dark-grey-filter);
}
.nav__link--alert .nav__link__icon {
  background-color: var(--app-dark-red);
}
</style>

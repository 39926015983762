<template>
  <div :class="`notification notification__${notification.type}`">
    <img class="notification__icon" v-if="notification.icon" :src="`${icons[notification.icon]}`" />
    <p class="notification__text">{{ notification.text }}</p>
  </div>
</template>
<script>
import {
  HIDE_NOTIFICATION
} from '@/core/action-types'
export default {
  name: 'notification-bar',
  props: [
    'notification'
  ],
  data: function () {
    return {
      icons: {
        info: '/img/baseline_info_white_48dp.png',
        success: '/img/baseline_check_circle_white_48dp.png',
        warning: '/img/baseline_warning_white_48dp.png',
        error: '/img/baseline_error_white_48dp.png'
      }
    }
  },
  mounted: function () {
    window.setTimeout(() => {
      this.$el.classList.add('notification--active')
      window.setTimeout(() => {
        this.$el.classList.remove('notification--active')
        window.setTimeout(() => {
          this.$store.dispatch(HIDE_NOTIFICATION)
        }, 1000)
      }, this.notification.type === 'error' ? 6000 : 3000)
    }, 0)
  }
}
</script>
<style scoped>
  .notification {
    position: fixed;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: 0 auto 10px;
    padding: 10px;
    background: linear-gradient(60deg, #e339ff, #af2cc5);
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2);
    width: 460px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .notification--active {
    bottom: 0;
  }
  .notification__info {
    background: linear-gradient(60deg, #23e6ff, #00d3ee);
  }
  .notification__success {
    background: linear-gradient(60deg, #6ddb72, #5cb860);
  }
  .notification__warning {
    background: linear-gradient(60deg, #ffb447, #ffa21a);
  }
  .notification__error {
    background: linear-gradient(60deg, #f88178, #f55a4e);
  }
  .notification__icon {
    flex-basis: 30px;
    width: 30px;
    margin: 0 10px 0 0;
  }
</style>
<i18n>
{
  "en": {
    "close": "close"
  },
  "es": {
    "close": "cerrar"
  }
}
</i18n>

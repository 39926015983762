// Dynamic module route
// import { routeGuard } from './route.guard'

export default [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      linkText: 'settings',
      imgSrc: '/img/baseline_settings_applications_white_48dp.png'
    },
    // beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "settings" */ './Settings.vue')
  }
]

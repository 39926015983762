import {
  GET_USERS,
  SAVE_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_PERMISSIONS
} from '@/core/action-types'
import {
  ADD_LOADING,
  REMOVE_LOADING,
  SET_USERS,
  ADD_USER,
  SET_USER,
  REMOVE_USER,
  SET_PERMISSIONS
} from '@/core/mutation-types'
import {
  HTTP_USERSDATA,
  HTTP_PERMISSIONS
} from '@/core/http-common'
import {
  notifyError
} from '@/core/store'

export default {
  state: {
    users: null,
    permissions: []
  },
  mutations: {
    [SET_USERS] (state, payload) {
      state.users = payload.data
    },
    [ADD_USER] (state, payload) {
      state.users.push(payload.data)
    },
    [SET_USER] (state, payload) {
      state.users[state.users.findIndex(item => item._id === payload.data._id)] = payload.data
      state.users = [...state.users]
    },
    [REMOVE_USER] (state, payload) {
      state.users.splice(state.users.findIndex(item => item._id === payload.id), 1)
      state.users = [...state.users]
    },
    [SET_PERMISSIONS] (state, payload) {
      state.permissions = payload.data
    }
  },
  actions: {
    async [GET_USERS] ({ commit, state, rootState }) {
      commit(ADD_LOADING, GET_USERS)
      HTTP_USERSDATA().get('/')
        .then(response => {
          commit(REMOVE_LOADING, GET_USERS)
          commit(SET_USERS, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_USERS)
          notifyError(error, commit)
        })
    },
    async [SAVE_NEW_USER] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_NEW_USER)
      HTTP_USERSDATA().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_USER)
          commit(ADD_USER, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_USER)
          notifyError(error, commit)
        })
    },
    async [UPDATE_USER] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPDATE_USER)
      HTTP_USERSDATA().put(`/${query.id}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_USER)
          commit(SET_USER, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_USER)
          notifyError(error, commit)
        })
    },
    async [DELETE_USER] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_USER)
      HTTP_USERSDATA().post(`/${query.id}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_USER)
          commit(REMOVE_USER, query)
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_USER)
          notifyError(error, commit)
        })
    },
    async [GET_PERMISSIONS] ({ commit, state, rootState }) {
      commit(ADD_LOADING, GET_PERMISSIONS)
      HTTP_PERMISSIONS().get('/')
        .then(response => {
          commit(REMOVE_LOADING, GET_PERMISSIONS)
          commit(SET_PERMISSIONS, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_PERMISSIONS)
          notifyError(error, commit)
        })
    }
  },
  getters: {
    users: state => state.users,
    permissions: state => state.permissions
  }
}

// Dynamic module route
import { beforeProfile } from './route.guard'

export default [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true,
      linkText: 'profile',
      imgSrc: '/img/baseline_exit_to_app_white_48dp.png'
    },
    beforeEnter: beforeProfile,
    component: () => import(/* webpackChunkName: "profile" */ './Profile.vue')
  },
  {
    path: '/fb-callback',
    redirect: '/profile'
  },
  {
    path: '/tw-callback',
    redirect: '/profile'
  }
]

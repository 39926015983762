import {
  GET_AGENCIES,
  GET_CLIENTS,
  GET_TEAMS,
  SAVE_NEW_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  GET_ORDERS,
  SAVE_NEW_ORDER,
  UPDATE_ORDER,
  REFRESH_ORDER,
  DELETE_ORDER,
  GET_ACCOUNTS,
  GET_CAMPAIGNS,
  GET_ACTIVE_CAMPAIGNS,
  SEARCH_CAMPAIGNS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_ADSETS,
  GET_ADSET_ADS,
  SAVE_NEW_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  SAVE_NEW_LINE_ITEM,
  REFRESH_LINE_ITEM,
  UPDATE_LINE_ITEM,
  DELETE_LINE_ITEM,
  REFRESH_CAMPAIGN,
  GET_ADSETS,
  SAVE_NEW_ADSET,
  UPDATE_ADSET,
  DELETE_ADSET,
  REFRESH_ADSET,
  GET_ADS,
  SAVE_NEW_AD,
  UPDATE_AD,
  DELETE_AD,
  REFRESH_AD,
  SAVE_NEW_CREATIVE,
  UPLOAD_VIDEO,
  GET_VIDEO_STATUS,
  FINISH_VIDEO_STATUS_PROCESSING,
  GET_TARGETING_FACEBOOK,
  GET_PAGES_FACEBOOK,
  GET_PAGES_INSTAGRAM,
  STORE_FILTERED_TEAMS,
  STORE_FILTERED_AGENCIES,
  STORE_FILTERED_CLIENTS,
  STORE_FILTERED_TEAM_NAMES,
  STORE_FILTERED_ORDERS,
  STORE_FILTERED_ORDER_NAMES,
  STORE_FILTERED_LINE_ITEMS,
  STORE_FILTERED_LINE_ITEM_NAMES,
  STORE_FILTERED_CAMPAIGNS,
  STORE_FILTERED_CAMPAIGN_NAMES,
  STORE_FILTERED_ADSETS,
  STORE_FILTERED_ADS,
  GET_AD_CREATIVE,
  STORE_FILTER_DATE_FROM,
  STORE_FILTER_DATE_TO,
  STORE_FILTER_SEARCH_TEXT,
  REDUCE_CAMPAIGN,
  GET_FACEBOOK_PIXELS,
  GET_FACEBOOK_CUSTOM_EVENTS
} from '@/core/action-types'
import {
  SET_AGENCIES,
  SET_CLIENTS,
  SET_TEAMS,
  ADD_TEAM,
  SET_TEAM,
  REMOVE_TEAM,
  SET_ORDERS,
  ADD_ORDER,
  SET_ORDER,
  REMOVE_ORDER,
  SET_ACCOUNTS,
  SET_CAMPAIGNS,
  SET_FOUND_CAMPAIGNS,
  ADD_CAMPAIGN,
  SET_CAMPAIGN,
  SET_CAMPAIGN_DATA,
  SET_CAMPAIGN_ADSETS,
  REMOVE_CAMPAIGN,
  ADD_LINE_ITEM,
  SET_LINE_ITEM,
  REMOVE_LINE_ITEM,
  ADD_LOADING,
  REMOVE_LOADING,
  SET_ADSETS,
  SET_ADSET_DATA,
  ADD_ADSET,
  SET_ADSET,
  REMOVE_ADSET,
  SET_ADS,
  SET_AD_DATA,
  ADD_AD,
  SET_AD,
  REMOVE_AD,
  SET_VIDEO_STATUS_PROCESSING,
  REMOVE_VIDEO_STATUS_PROCESSING,
  SET_TARGETING_FACEBOOK,
  SET_PAGES_FACEBOOK,
  SET_PAGES_INSTAGRAM,
  SET_FILTERED_TEAMS,
  SET_FILTERED_AGENCIES,
  SET_FILTERED_CLIENTS,
  SET_FILTERED_TEAM_NAMES,
  SET_FILTERED_ORDERS,
  SET_FILTERED_ORDER_NAMES,
  SET_FILTERED_LINE_ITEMS,
  SET_FILTERED_LINE_ITEM_NAMES,
  SET_FILTERED_CAMPAIGNS,
  SET_FILTERED_ADSETS,
  SET_FILTERED_ADS,
  SET_FILTERED_CAMPAIGN_NAMES,
  SET_FILTER_DATE_FROM,
  SET_FILTER_DATE_TO,
  SET_FILTERED_SEARCH_TEXT,
  SET_FACEBOOK_PIXELS,
  SET_FACEBOOK_CUSTOM_EVENTS,
  RESET_DASHBOARD_STORE
} from '@/core/mutation-types'
import {
  HTTP_TEAMSDATA,
  HTTP_CAMPAIGNS,
  HTTP_CAMPAIGNS_SEARCH,
  HTTP_LINE_ITEMS,
  HTTP_CAMPAIGN,
  HTTP_ADSETS,
  HTTP_ADS,
  HTTP_CREATIVE,
  HTTP_VIDEOS,
  HTTP_ACCOUNTS,
  HTTP_ORDERS,
  HTTP_TARGETING_FACEBOOK,
  HTTP_PAGES,
  HTTP_INSTAGRAM_PAGES,
  HTTP_ACTIVE_CAMPAIGNS
} from '@/core/http-common'
import {
  notifyError
} from '@/core/store'

export default {
  state: {
    dateFilterFrom: null,
    dateFilterTo: null,
    teams: [],
    agencies: [],
    clients: [],
    orders: [],
    accounts: [],
    campaigns: [],
    adsets: [],
    ads: [],
    videoStatus: [],
    campaign: null,
    targeting: null,
    pages: {},
    filteredTeams: [],
    filteredAgencies: [],
    filteredClients: [],
    filteredTeamNames: '',
    filteredOrders: [],
    filteredOrderNames: '',
    filteredLineItems: [],
    filteredLineItemNames: '',
    filteredCampaigns: [],
    filteredCampaignNames: '',
    filteredAdsets: [],
    filteredAds: [],
    filteredSearchText: '',
    facebookPixels: [],
    facebookCustomEvents: []
  },
  mutations: {
    [RESET_DASHBOARD_STORE] (state) {
      state = {
        dateFilterFrom: null,
        dateFilterTo: null,
        teams: [],
        agencies: [],
        clients: [],
        orders: [],
        accounts: [],
        campaigns: [],
        adsets: [],
        ads: [],
        videoStatus: [],
        campaign: null,
        targeting: null,
        pages: {},
        filteredTeams: [],
        filteredAgencies: [],
        filteredClients: [],
        filteredTeamNames: '',
        filteredOrders: [],
        filteredOrderNames: '',
        filteredLineItems: [],
        filteredLineItemNames: '',
        filteredCampaigns: [],
        filteredCampaignNames: '',
        filteredAdsets: [],
        filteredAds: [],
        filteredSearchText: '',
        facebookPixels: [],
        facebookCustomEvents: []
      }
    },
    [SET_FILTER_DATE_FROM] (state, payload) {
      state.dateFilterFrom = payload.data
    },
    [SET_FILTER_DATE_TO] (state, payload) {
      state.dateFilterTo = payload.data
    },
    [SET_AGENCIES] (state, payload) {
      state.agencies = payload.data
    },
    [SET_CLIENTS] (state, payload) {
      state.clients = payload.data
    },
    [SET_TEAMS] (state, payload) {
      state.teams = payload.data
    },
    [ADD_TEAM] (state, payload) {
      state.teams.push(payload.data)
    },
    [SET_TEAM] (state, payload) {
      state.teams[state.teams.findIndex(item => item._id === payload.data._id)] = payload.data
      state.teams = [...state.teams]
    },
    [REMOVE_TEAM] (state, payload) {
      state.teams.splice(state.teams.findIndex(item => item._id === payload.id), 1)
      state.teams = [...state.teams]
    },
    [SET_ORDERS] (state, payload) {
      state.orders = payload.data
    },
    [ADD_ORDER] (state, payload) {
      state.orders.push(payload.data)
    },
    [SET_ORDER] (state, payload) {
      state.orders[state.orders.findIndex(item => item._id === payload.data._id)] = payload.data
      state.orders = [...state.orders]
    },
    [REMOVE_ORDER] (state, payload) {
      state.orders.splice(state.orders.findIndex(item => item._id === payload.id), 1)
      state.orders = [...state.orders]
    },
    [SET_ACCOUNTS] (state, payload) {
      state.accounts = payload.data
    },
    [ADD_LINE_ITEM] (state, payload) {
      let order = state.orders[state.orders.findIndex(item => item._id === payload.order_id)]
      order.line_items.push(payload.data)
      state.orders = [...state.orders]
    },
    [SET_LINE_ITEM] (state, payload) {
      let order = state.orders[state.orders.findIndex(item => item._id === payload.order_id)]
      order.line_items[order.line_items.findIndex(item => item._id === payload._id)] = payload.data
      state.orders = [...state.orders]
    },
    [REMOVE_LINE_ITEM] (state, payload) {
      let order = state.orders[state.orders.findIndex(item => item._id === payload.order_id)]
      order.line_items.splice(order.line_items.findIndex(item => item._id === payload.id), 1)
      state.orders = [...state.orders]
    },
    [SET_CAMPAIGNS] (state, payload) {
      state.campaigns = payload.data
    },
    [SET_FOUND_CAMPAIGNS] (state, payload) {
      state.foundCampaigns = payload.data
    },
    [ADD_CAMPAIGN] (state, payload) {
      // TODO: WTF???
      // state.campaign = payload.data
    },
    [SET_CAMPAIGN] (state, payload) {
      // TODO: WTF???
      // state.campaign = payload.data
    },
    [REMOVE_CAMPAIGN] (state, payload) {
      // TODO: WTF???
      // state.campaign = payload.data
    },
    [SET_CAMPAIGN_DATA] (state, payload) {
      state.campaign = payload.data
    },
    [SET_ADSET_DATA] (state, payload) {
      state.adset = payload.data
    },
    [SET_AD_DATA] (state, payload) {
      state.ad = payload.data
    },
    // [SET_CAMPAIGN_ADSETS] (state, payload) {
    //   state.campaignAdsets = payload.data
    // },
    [SET_ADSETS] (state, payload) {
      state.adsets = payload.data
    },
    [ADD_ADSET] (state, payload) {
      state.adsets.push(payload.data)
    },
    [SET_ADSET] (state, payload) {
      state.adsets[state.adsets.findIndex(item => item._id === payload.data._id)] = payload.data
      state.adsets = [...state.adsets]
    },
    [REMOVE_ADSET] (state, payload) {
      state.adsets.splice(state.adsets.findIndex(item => item._id === payload.id), 1)
      state.adsets = [...state.adsets]
    },
    [SET_ADS] (state, payload) {
      state.ads = payload.data
    },
    [ADD_AD] (state, payload) {
      state.ads.push(payload.data)
    },
    [SET_AD] (state, payload) {
      state.ads[state.ads.findIndex(item => item._id === payload.data._id)] = payload.data
      state.ads = [...state.ads]
    },
    [REMOVE_AD] (state, payload) {
      state.ads.splice(state.ads.findIndex(item => item._id === payload.id), 1)
      state.ads = [...state.ads]
    },
    [SET_TARGETING_FACEBOOK] (state, payload) {
      console.log(SET_TARGETING_FACEBOOK, payload.data)
      state.targeting = {
        ...state.targeting,
        facebook: payload.data
      }
    },
    [SET_PAGES_FACEBOOK] (state, payload) {
      // FORMAT:
      // {
      //   "api_id": "14176469070",
      //   "name": "Filmax",
      //   "platform": 1
      // }
      state.pages = {
        ...state.pages,
        facebook: payload.data.pages
      }
    },
    [SET_PAGES_INSTAGRAM] (state, payload) {
      // FORMAT:
      // {
      //   "api_id": "14176469070",
      //   "name": "Filmax",
      //   "platform": 1
      // }
      state.pages = {
        ...state.pages,
        instagram: payload.data.instagrams
      }
    },
    [SET_FILTERED_TEAMS] (state, payload) {
      state.filteredTeams = payload.data
    },
    [SET_FILTERED_AGENCIES] (state, payload) {
      state.filteredAgencies = payload.data
    },
    [SET_FILTERED_CLIENTS] (state, payload) {
      state.filteredClients = payload.data
    },
    [SET_FILTERED_TEAM_NAMES] (state, payload) {
      state.filteredTeamNames = payload.data
    },
    [SET_FILTERED_ORDERS] (state, payload) {
      state.filteredOrders = payload.data
    },
    [SET_FILTERED_ORDER_NAMES] (state, payload) {
      state.filteredOrderNames = payload.data
    },
    [SET_FILTERED_LINE_ITEMS] (state, payload) {
      state.filteredLineItems = payload.data
    },
    [SET_FILTERED_LINE_ITEM_NAMES] (state, payload) {
      state.filteredLineItemNames = payload.data
    },
    [SET_FILTERED_CAMPAIGNS] (state, payload) {
      state.filteredCampaigns = payload.data
    },
    [SET_FILTERED_CAMPAIGN_NAMES] (state, payload) {
      state.filteredCampaignNames = payload.data
    },
    [SET_FILTERED_ADSETS] (state, payload) {
      state.filteredAdsets = payload.data
    },
    [SET_FILTERED_ADS] (state, payload) {
      state.filteredAds = payload.data
    },
    [SET_FILTERED_SEARCH_TEXT] (state, payload) {
      state.filteredSearchText = payload.data
    },
    [SET_FACEBOOK_PIXELS] (state, payload) {
      state.facebookPixels = payload.data
    },
    [SET_FACEBOOK_CUSTOM_EVENTS] (state, payload) {
      state.facebookCustomEvents = payload.data
    },
    [SET_VIDEO_STATUS_PROCESSING] (state, payload) {
      state.videoStatus.push(payload.data.id)
    },
    [REMOVE_VIDEO_STATUS_PROCESSING] (state, payload) {
      state.videoStatus.splice(state.videoStatus.findIndex(item => item._id === payload.id), 1)
      state.videoStatus = [...state.videoStatus]
    }
  },
  actions: {
    async [GET_ACCOUNTS] ({ commit, state, rootState }, query) {
      let realQuery = { limit: 1000 }
      if (query && query.team_id) {
        realQuery.team_id = query.team_id
      }
      if (query && query.platform) {
        realQuery.platform = query.platform
      }
      commit(ADD_LOADING, GET_ACCOUNTS)
      HTTP_ACCOUNTS().get('/', { params: realQuery })
        .then(response => {
          commit(REMOVE_LOADING, GET_ACCOUNTS)
          commit(SET_ACCOUNTS, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_ACCOUNTS)
          notifyError(error, commit)
        })
    },
    async [GET_AGENCIES] ({ commit, state, rootState }, query) {
      let realQuery = {
        roles: 'agency'
      }
      if (query.team_id) {
        realQuery.team_id = query.team_id
      }
      if (state.filteredSearchText) {
        realQuery.q = state.filteredSearchText
      }
      commit(ADD_LOADING, GET_AGENCIES)
      HTTP_TEAMSDATA().get('/', (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, GET_AGENCIES)
          commit(SET_AGENCIES, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_AGENCIES)
          notifyError(error, commit)
        })
    },
    async [GET_CLIENTS] ({ commit, state, rootState }, query) {
      let realQuery = {
        roles: 'client'
      }
      if (query.team_id) {
        realQuery.team_id = query.team_id
      }
      if (state.filteredSearchText) {
        realQuery.q = state.filteredSearchText
      }
      commit(ADD_LOADING, GET_CLIENTS)
      HTTP_TEAMSDATA().get('/', (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, GET_CLIENTS)
          commit(SET_CLIENTS, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_CLIENTS)
          notifyError(error, commit)
        })
    },
    async [GET_TEAMS] ({ commit, state, rootState }, query) {
      let realQuery = {}
      if (query.roles) {
        realQuery.roles = query.roles
      }
      if (query.team_id) {
        realQuery.team_id = query.team_id
      }
      // TODO: Comprobar si es necesario, esta query ahora solo se usa desde el admin:
      if (state.filteredSearchText) {
        realQuery.q = state.filteredSearchText
      }
      commit(ADD_LOADING, GET_TEAMS)
      HTTP_TEAMSDATA().get('/', (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, GET_TEAMS)
          commit(SET_TEAMS, response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_TEAMS)
          notifyError(error, commit)
        })
    },
    async [SAVE_NEW_TEAM] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_NEW_TEAM)
      HTTP_TEAMSDATA().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_TEAM)
          commit(ADD_TEAM, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_TEAM)
          notifyError(error, commit)
        })
    },
    async [UPDATE_TEAM] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPDATE_TEAM)
      HTTP_TEAMSDATA().put(`/${query.id}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_TEAM)
          commit(SET_TEAM, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_TEAM)
          notifyError(error, commit)
        })
    },
    async [DELETE_TEAM] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_TEAM)
      HTTP_TEAMSDATA().post(`/${query.id}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_TEAM)
          commit(REMOVE_TEAM, query)
          if (query.role === 'agency' && state.filteredAgencies.indexOf(query.id) >= 0) {
            state.filteredAgencies.splice(state.filteredAgencies.indexOf(query.id), 1)
            commit(SET_FILTERED_AGENCIES, { data: [...state.filteredAgencies] })
          }
          if (query.role === 'client' && state.filteredClients.indexOf(query.id) >= 0) {
            state.filteredClients.splice(state.filteredClients.indexOf(query.id), 1)
            commit(SET_FILTERED_CLIENTS, { data: [...state.filteredClients] })
          }
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_TEAM)
          notifyError(error, commit)
        })
    },
    async [GET_ORDERS] ({ commit, state, rootState }, query) {
      let realQuery = {}
      // TODO: NO SE SI REALMENTE SIGUEN EXISTIENDO LAS acounts!!!
      // if (query && query.accounts && query.accounts.length > 0) {
      //   realQuery.accounts = query.accounts
      // }
      // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
      // if (query && query.teams && query.teams.length > 0) {
      //   realQuery.teams = query.teams
      // } else if (state.filteredTeams && state.filteredTeams.length > 0) {
      //   realQuery.teams = state.filteredTeams.join(',')
      // }
      if (state.filteredClients && state.filteredClients.length > 0) {
        realQuery.teams = state.filteredClients.join(',')
      }
      if (state.filteredSearchText) {
        realQuery.q = state.filteredSearchText
      }
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      commit(ADD_LOADING, GET_ORDERS)
      HTTP_ORDERS().get('/', (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, GET_ORDERS)
          commit(SET_ORDERS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_ORDERS)
          notifyError(error, commit)
        })
    },
    async [SAVE_NEW_ORDER] ({ dispatch, commit, state, rootState }, query) {
      let ordersQuery = {}
      commit(ADD_LOADING, SAVE_NEW_ORDER)
      if (query && query.teams && query.teams.length > 0) {
        ordersQuery.teams = query.teams
        delete query.teams
      }
      HTTP_ORDERS().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_ORDER)
          commit(ADD_ORDER, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_ORDERS, ordersQuery)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_ORDER)
          notifyError(error, commit)
        })
    },
    async [REFRESH_ORDER] ({ commit, state, rootState }, query) {
      console.log(query)
      commit(ADD_LOADING, REFRESH_ORDER)
      const queryID = query.id
      delete query.id
      let realQuery = {}
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      HTTP_ORDERS().get(`/${queryID}/update`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, REFRESH_ORDER)
          commit(SET_ORDER, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete()
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, REFRESH_ORDER)
          notifyError(error, commit)
        })
    },
    async [UPDATE_ORDER] ({ dispatch, commit, state, rootState }, query) {
      let ordersQuery = {}
      commit(ADD_LOADING, UPDATE_ORDER)
      const queryID = query.id
      delete query.id
      if (query && query.teams && query.teams.length > 0) {
        ordersQuery.teams = query.teams
        delete query.teams
      }
      HTTP_ORDERS().put(`/${queryID}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_ORDER)
          commit(SET_ORDER, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_ORDERS, ordersQuery)
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_ORDER)
          notifyError(error, commit)
        })
    },
    async [DELETE_ORDER] ({ dispatch, commit, state, rootState }, query) {
      let ordersQuery = {}
      commit(ADD_LOADING, DELETE_ORDER)
      const queryID = query.id
      delete query.id
      if (query && query.teams && query.teams.length > 0) {
        ordersQuery.teams = query.teams
        delete query.teams
      }
      HTTP_ORDERS().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_ORDER)
          commit(REMOVE_ORDER, query)
          state.filteredOrders.splice(state.filteredOrders.indexOf(queryID), 1)
          commit(SET_FILTERED_ORDERS, { data: [...state.filteredOrders] })
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_ORDERS, ordersQuery)
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_ORDER)
          notifyError(error, commit)
        })
    },
    async [GET_ACTIVE_CAMPAIGNS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_ACTIVE_CAMPAIGNS)
      return HTTP_ACTIVE_CAMPAIGNS().get('/')
        .then(response => {
          commit(REMOVE_LOADING, GET_ACTIVE_CAMPAIGNS)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_ACTIVE_CAMPAIGNS)
          notifyError(error, commit)
        })
    },
    async [GET_CAMPAIGNS] ({ commit, state, rootState }, query) {
      let realQuery = {}
      if (query && query.directSearch === true) {
        realQuery.campaign_ids = query.campaign_ids.join(',')
        commit(ADD_LOADING, GET_CAMPAIGNS)
        // return HTTP_ACTIVE_CAMPAIGNS().get('/', { params: realQuery })
        return HTTP_CAMPAIGNS().get('/', { params: realQuery })
          .then(response => {
            commit(REMOVE_LOADING, GET_CAMPAIGNS)
            if (query && query.onComplete && typeof query.onComplete === 'function') {
              query.onComplete(response.data)
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, GET_CAMPAIGNS)
            notifyError(error, commit)
          })
      } else {
        // if (state.filteredClients && state.filteredClients.length > 0) {
        //   realQuery.teams = state.filteredClients.join(',')
        // }
        if (state.filteredLineItems && state.filteredLineItems.length > 0) {
          realQuery.campaign_ids = []
          let filteredCampaigns = []
          const orders = state.orders
          state.filteredLineItems.forEach(filteredLineItemId => {
            const order = orders.find(order => order.line_items.find(lineItem => lineItem._id === filteredLineItemId))
            if (order) {
              const lineItem = order.line_items.find(lineItem => lineItem._id === filteredLineItemId)
              if (lineItem.campaign_ids && lineItem.campaign_ids.length > 0) {
                filteredCampaigns.push(...lineItem.campaign_ids)
              }
            }
          })
          // CAMBIAR A FILTRAR CAMPAÑAS DIRECTAMENTE:
          realQuery.campaign_ids = filteredCampaigns.join(',')
        }
        // if (state.filteredCampaigns && state.filteredCampaigns.length > 0) {
        //   realQuery.campaign_ids = realQuery.campaign_ids.push(...state.filteredCampaigns).join(',')
        // }
        if (state.filteredSearchText) {
          realQuery.q = state.filteredSearchText
        }
        if (state.dateFilterFrom) {
          realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
        }
        if (state.dateFilterTo) {
          realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
        }
        commit(ADD_LOADING, GET_CAMPAIGNS)
        HTTP_CAMPAIGNS().get('/', (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
          .then(response => {
            commit(REMOVE_LOADING, GET_CAMPAIGNS)
            commit(SET_CAMPAIGNS, response.data)
            if (query && query.onComplete && typeof query.onComplete === 'function') {
              query.onComplete(response.data)
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, GET_CAMPAIGNS)
            notifyError(error, commit)
          })
      }
    },
    async [SEARCH_CAMPAIGNS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, SEARCH_CAMPAIGNS)
      HTTP_CAMPAIGNS_SEARCH().post(query.params || '/', query)
        .then(response => {
          commit(REMOVE_LOADING, SEARCH_CAMPAIGNS)
          commit(SET_FOUND_CAMPAIGNS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SEARCH_CAMPAIGNS)
          notifyError(error, commit)
        })
    },
    async [SAVE_NEW_LINE_ITEM] ({ dispatch, commit, state, rootState }, query) {
      let ordersQuery = {}
      let orderId = query.order_id
      commit(ADD_LOADING, SAVE_NEW_LINE_ITEM)
      if (query && query.teams && query.teams.length > 0) {
        ordersQuery.teams = query.teams
        delete query.teams
      }
      HTTP_LINE_ITEMS().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_LINE_ITEM)
          commit(ADD_LINE_ITEM, { ...response.data, order_id: orderId })
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_ORDERS, ordersQuery)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_LINE_ITEM)
          notifyError(error, commit)
        })
    },
    async [REFRESH_LINE_ITEM] ({ commit, state, rootState }, query) {
      console.log(query.id)
      commit(ADD_LOADING, REFRESH_LINE_ITEM)
      const queryID = query.id
      delete query.id
      let realQuery = {}
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      HTTP_LINE_ITEMS().get(`/${queryID}/update`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, REFRESH_LINE_ITEM)
          commit(SET_ORDER, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete()
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, REFRESH_LINE_ITEM)
          notifyError(error, commit)
        })
    },
    async [UPDATE_LINE_ITEM] ({ dispatch, commit, state, rootState }, query) {
      let ordersQuery = {}
      let orderId = query.order_id
      delete query.order_id
      commit(ADD_LOADING, UPDATE_LINE_ITEM)
      const queryID = query.id
      delete query.id
      if (query.teams && query.teams.length > 0) {
        ordersQuery.teams = query.teams
        delete query.teams
      }
      HTTP_LINE_ITEMS().put(`/${queryID}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_LINE_ITEM)
          commit(SET_LINE_ITEM, { ...response.data, order_id: orderId })
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_ORDERS, ordersQuery)
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_LINE_ITEM)
          notifyError(error, commit)
        })
    },
    async [DELETE_LINE_ITEM] ({ dispatch, commit, state, rootState }, query) {
      let ordersQuery = {}
      commit(ADD_LOADING, DELETE_LINE_ITEM)
      const queryID = query.id
      if (query && query.teams && query.teams.length > 0) {
        ordersQuery.teams = query.teams
        delete query.teams
      }
      HTTP_LINE_ITEMS().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_LINE_ITEM)
          let orderId = ''
          state.orders.forEach(order => {
            const foundItem = order.line_items.find(lineItem => lineItem._id === queryID)
            if (foundItem) {
              orderId = order._id
            }
          })
          commit(REMOVE_LINE_ITEM, { id: queryID, order_id: orderId })
          state.filteredLineItems.splice(state.filteredLineItems.indexOf(queryID), 1)
          commit(SET_FILTERED_LINE_ITEMS, { data: [...state.filteredLineItems] })
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_ORDERS, ordersQuery)
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_LINE_ITEM)
          notifyError(error, commit)
        })
    },
    async [GET_CAMPAIGN] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_CAMPAIGN)
      const queryID = query.id
      delete query.id
      HTTP_CAMPAIGN().get(`/${queryID}`)
        .then(response => {
          commit(REMOVE_LOADING, GET_CAMPAIGN)
          commit(SET_CAMPAIGN_DATA, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_CAMPAIGN)
          notifyError(error, commit)
        })
    },
    async [GET_CAMPAIGN_ADSETS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_CAMPAIGN_ADSETS)
      const queryID = query.id
      delete query.id
      let realQuery = {}
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      HTTP_CAMPAIGN().get(`/${queryID}/adsets`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, GET_CAMPAIGN_ADSETS)
          commit(SET_CAMPAIGN_ADSETS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_CAMPAIGN_ADSETS)
          notifyError(error, commit)
        })
    },
    async [GET_ADSET_ADS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_ADSET_ADS)
      // const queryID = query.id
      // delete query.id
      // HTTP_CAMPAIGN().get(`/${queryID}`)
      //   .then(response => {
      //     commit(REMOVE_LOADING, GET_ADSET_ADS)
      //     commit(SET_CAMPAIGN_DATA, response.data)
      //     if (query && query.onComplete && typeof query.onComplete === 'function') {
      //       query.onComplete(response.data)
      //     }
      //   })
      //   .catch(error => {
      //     commit(REMOVE_LOADING, GET_ADSET_ADS)
      //     notifyError(error, commit)
      //   })
    },
    async [REFRESH_CAMPAIGN] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, REFRESH_CAMPAIGN)
      const queryID = query.id
      delete query.id
      let realQuery = {}
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      HTTP_CAMPAIGN().get(`/${queryID}/update`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, REFRESH_CAMPAIGN)
          commit(SET_CAMPAIGN_DATA, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete()
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, REFRESH_CAMPAIGN)
          notifyError(error, commit)
        })
    },
    async [REFRESH_ADSET] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, REFRESH_ADSET)
      const queryID = query.id
      delete query.id
      let realQuery = {}
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      HTTP_ADSETS().get(`/${queryID}/update`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, REFRESH_ADSET)
          commit(SET_ADSET_DATA, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete()
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, REFRESH_ADSET)
          notifyError(error, commit)
        })
    },
    async [REFRESH_AD] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, REFRESH_AD)
      const queryID = query.id
      delete query.id
      let realQuery = {}
      if (state.dateFilterFrom) {
        realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
      }
      if (state.dateFilterTo) {
        realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
      }
      HTTP_ADS().get(`/${queryID}/update`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
        .then(response => {
          commit(REMOVE_LOADING, REFRESH_AD)
          commit(SET_AD_DATA, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete()
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, REFRESH_AD)
          notifyError(error, commit)
        })
    },
    async [REDUCE_CAMPAIGN] ({ dispatch, commit, state, rootState }, query) {
      if (query && query.onComplete && typeof query.onComplete === 'function') {
        const campaign = state.campaigns.find(campaign => campaign._id === query.id)
        query.onComplete(campaign)
      }
    },
    async [SAVE_NEW_CAMPAIGN] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_NEW_CAMPAIGN)
      delete query.id
      const lineItemId = query.lineItemId
      delete query.lineItemId
      HTTP_CAMPAIGN().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_CAMPAIGN)
          let campaignId = response.data &&
            response.data &&
            response.data.data &&
            response.data.data._id
            ? response.data.data._id
            : response.data &&
              response.data &&
              response.data._id
              ? response.data._id
              : undefined
          state.filteredCampaigns.push(campaignId)
          commit(SET_FILTERED_CAMPAIGNS, { data: [...state.filteredCampaigns] })
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          // dispatch(GET_CAMPAIGNS)
          // TODO: TESTEAR QUÉ SUCEDE SI FACEBOOK DEVUELVE UN ERROR!
          if (lineItemId !== undefined && campaignId !== undefined) {
            let lineItemCampaignIds = []
            let orderId = ''
            state.orders.forEach(order => {
              const foundItem = order.line_items.find(lineItem => lineItem._id === lineItemId)
              if (foundItem) {
                orderId = order._id
                lineItemCampaignIds = foundItem.campaign_ids
              }
            })
            // const lineItemCampaignIds = state.orders.map(order => order.line_items).flat().find(lineItem => lineItem._id === lineItemId).campaign_ids
            dispatch(UPDATE_LINE_ITEM, { id: lineItemId, campaign_ids: [...lineItemCampaignIds, campaignId], order_id: orderId })
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_CAMPAIGN)
          notifyError(error, commit)
        })
    },
    async [UPDATE_CAMPAIGN] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPDATE_CAMPAIGN)
      const queryID = query.id
      delete query.id
      HTTP_CAMPAIGN().put(`/${queryID}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_CAMPAIGN)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_CAMPAIGNS)
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_CAMPAIGN)
          notifyError(error, commit)
        })
    },
    async [DELETE_CAMPAIGN] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_CAMPAIGN)
      const queryID = query.id
      delete query.id
      HTTP_CAMPAIGN().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_CAMPAIGN)
          state.filteredCampaigns.splice(state.filteredCampaigns.indexOf(queryID), 1)
          commit(SET_FILTERED_CAMPAIGNS, { data: [...state.filteredCampaigns] })
          // TODO: TESTEAR QUÉ SUCEDE SI FACEBOOK DEVUELVE UN ERROR!
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
          dispatch(GET_CAMPAIGNS)
          dispatch(GET_ORDERS)
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_CAMPAIGN)
          notifyError(error, commit)
        })
    },
    async [GET_ADSETS] ({ commit, state, rootState }, query) {
      let realQuery = {}
      if (query && query.directSearch === true) {
        realQuery.campaign_ids = query.campaign_ids.join(',')
        if (query.adset_ids && query.adset_ids.length > 0) {
          realQuery.adset_ids = query.adset_ids.join(',')
        }
        commit(ADD_LOADING, GET_ADSETS)
        return HTTP_ADSETS().get('/', { params: realQuery })
          .then(response => {
            commit(REMOVE_LOADING, GET_ADSETS)
            if (query && query.onComplete && typeof query.onComplete === 'function') {
              query.onComplete(response.data)
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, GET_ADSETS)
            notifyError(error, commit)
          })
      } else {
        // if (query && query.accounts && query.accounts.length > 0) {
        //   realQuery.accounts = query.accounts
        // }
        // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
        // if (query && query.teams && query.teams.length > 0) {
        //   realQuery.team_ids = query.teams
        // } else if (state.filteredTeams && state.filteredTeams.length > 0) {
        //   realQuery.team_ids = state.filteredTeams.join(',')
        // }
        // if (state.filteredClients && state.filteredClients.length > 0) {
        //   realQuery.teams = state.filteredClients.join(',')
        // }
        // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
        // if (state.filteredOrders && state.filteredOrders.length > 0) {
        //   realQuery.order_ids = state.filteredOrders.join(',')
        // }
        // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
        // if (state.filteredLineItems && state.filteredLineItems.length > 0) {
        //   realQuery.line_item_ids = state.filteredLineItems.join(',')
        // }
        if (state.filteredCampaigns && state.filteredCampaigns.length > 0) {
          realQuery.campaign_ids = state.filteredCampaigns.join(',')
        }
        if (state.filteredSearchText) {
          realQuery.q = state.filteredSearchText
        }
        if (state.dateFilterFrom) {
          realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
        }
        if (state.dateFilterTo) {
          realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
        }
        commit(ADD_LOADING, GET_ADSETS)
        HTTP_ADSETS().get(`/`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
          .then(response => {
            commit(REMOVE_LOADING, GET_ADSETS)
            commit(SET_ADSETS, response.data)
            if (query && query.onComplete && typeof query.onComplete === 'function') {
              query.onComplete(response.data)
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, GET_ADSETS)
            notifyError(error, commit)
          })
      }
    },
    async [SAVE_NEW_ADSET] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_NEW_ADSET)
      HTTP_ADSETS().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_ADSET)
          commit(ADD_ADSET, response.data)
          // TODO: TESTEAR QUÉ SUCEDE SI FACEBOOK DEVUELVE UN ERROR!
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_ADSET)
          notifyError(error, commit)
        })
    },
    async [UPDATE_ADSET] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPDATE_ADSET)
      const queryID = query.id
      delete query.id
      HTTP_ADSETS().put(`/${queryID}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_ADSET)
          commit(SET_ADSET, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_ADSET)
          notifyError(error, commit)
        })
    },
    async [DELETE_ADSET] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_ADSET)
      const queryID = query.id
      delete query.id
      HTTP_ADSETS().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_ADSET)
          state.filteredAdsets.splice(state.filteredAdsets.indexOf(queryID), 1)
          commit(SET_FILTERED_ADSETS, { data: [...state.filteredAdsets] })
          // TODO: TESTEAR QUÉ SUCEDE SI FACEBOOK DEVUELVE UN ERROR!
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_ADSET)
          notifyError(error, commit)
        })
    },
    async [GET_ADS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_ADS)
      let realQuery = {}
      if (query && query.directSearch === true) {
        realQuery.campaign_ids = query.campaign_ids.join(',')
        realQuery.adset_ids = query.adset_ids.join(',')
        HTTP_ADS().get(`/`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
          .then(response => {
            commit(REMOVE_LOADING, GET_ADS)
            if (query && query.onComplete && typeof query.onComplete === 'function') {
              query.onComplete(response.data)
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, GET_ADS)
            notifyError(error, commit)
          })
      } else {
        // if (query && query.accounts && query.accounts.length > 0) {
        //   realQuery.accounts = query.accounts
        // }
        // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
        // if (query && query.teams && query.teams.length > 0) {
        //   realQuery.team_ids = query.teams
        // } else if (state.filteredTeams && state.filteredTeams.length > 0) {
        //   realQuery.team_ids = state.filteredTeams.join(',')
        // }
        // if (state.filteredClients && state.filteredClients.length > 0) {
        //   realQuery.teams = state.filteredClients.join(',')
        // }
        // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
        // if (state.filteredOrders && state.filteredOrders.length > 0) {
        //   realQuery.order_ids = state.filteredOrders.join(',')
        // }
        // TODO: REVISAR EL NOMBRE DE ESTE PARAMETRO
        // if (state.filteredLineItems && state.filteredLineItems.length > 0) {
        //   realQuery.line_item_ids = state.filteredLineItems.join(',')
        // }
        if (state.filteredCampaigns && state.filteredCampaigns.length > 0) {
          realQuery.campaign_ids = state.filteredCampaigns.join(',')
        }
        if (state.filteredAdsets && state.filteredAdsets.length > 0) {
          realQuery.adset_ids = state.filteredAdsets.join(',')
        }
        if (state.filteredSearchText) {
          realQuery.q = state.filteredSearchText
        }
        if (state.dateFilterFrom) {
          realQuery.sd = `${state.dateFilterFrom.getFullYear()}-${String(state.dateFilterFrom.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterFrom.getDate()).padStart(2, '0')}`
        }
        if (state.dateFilterTo) {
          realQuery.ed = `${state.dateFilterTo.getFullYear()}-${String(state.dateFilterTo.getMonth() + 1).padStart(2, '0')}-${String(state.dateFilterTo.getDate()).padStart(2, '0')}`
        }
        HTTP_ADS().get(`/`, (Object.keys(realQuery).length > 0) ? { params: realQuery } : null)
          .then(response => {
            commit(REMOVE_LOADING, GET_ADS)
            commit(SET_ADS, response.data)
            if (query && query.onComplete && typeof query.onComplete === 'function') {
              query.onComplete(response.data)
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, GET_ADS)
            notifyError(error, commit)
          })
      }
    },
    async [SAVE_NEW_AD] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, SAVE_NEW_AD)
      let realQuery = {}
      realQuery.account_id = query.account_id
      realQuery.name = query.name
      realQuery.adset = query.adset
      realQuery.platform = query.platform
      realQuery.status = query.status
      realQuery.creative = query.creative
      HTTP_ADS().post('/', realQuery)
        .then(response => {
          commit(REMOVE_LOADING, SAVE_NEW_AD)
          // TODO: TESTEAR QUÉ SUCEDE SI FACEBOOK DEVUELVE UN ERROR!
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, SAVE_NEW_AD)
          notifyError(error, commit)
        })
    },
    async [UPDATE_AD] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPDATE_AD)
      const queryID = query.id
      delete query.id
      HTTP_ADS().put(`/${queryID}`, { ...query, _method: 'PUT' })
        .then(response => {
          commit(REMOVE_LOADING, UPDATE_AD)
          commit(SET_ADSET, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPDATE_AD)
          notifyError(error, commit)
        })
    },
    async [DELETE_AD] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, DELETE_AD)
      const queryID = query.id
      delete query.id
      HTTP_ADS().post(`/${queryID}`, { _method: 'DELETE' })
        .then(response => {
          commit(REMOVE_LOADING, DELETE_AD)
          state.filteredAds.splice(state.filteredAds.indexOf(queryID), 1)
          commit(SET_FILTERED_ADS, { data: [...state.filteredAds] })
          // TODO: TESTEAR QUÉ SUCEDE SI FACEBOOK DEVUELVE UN ERROR!
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, DELETE_AD)
          notifyError(error, commit)
        })
    },
    async [SAVE_NEW_CREATIVE] ({ dispatch, commit, state, rootState }, query) {
      if (query.creative !== undefined && query.creative.creative_id !== undefined) {
        delete query.createAdAfterCreative
        // If we receive a creative then it's not necessary to create a new one, jump to the ad creation:
        dispatch(SAVE_NEW_AD, query)
      } else {
        commit(ADD_LOADING, SAVE_NEW_CREATIVE)
        HTTP_CREATIVE().post('/', query.creativeForm)
          .then(response => {
            commit(REMOVE_LOADING, SAVE_NEW_CREATIVE)
            if (query.createAdAfterCreative === true) {
              delete query.createAdAfterCreative
              delete query.creativeForm
              query.creative.creative_id = response.data.creative_id
              dispatch(SAVE_NEW_AD, query)
            } else {
              if (query && query.onComplete && typeof query.onComplete === 'function') {
                query.onComplete(response.data)
              }
            }
          })
          .catch(error => {
            commit(REMOVE_LOADING, SAVE_NEW_CREATIVE)
            notifyError(error, commit)
          })
      }
    },
    async [UPLOAD_VIDEO] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, UPLOAD_VIDEO)
      let formData = new FormData()
      formData.append('name', query.name)
      formData.append('platform', query.platform)
      formData.append('video', query.video)
      HTTP_VIDEOS().post(`/${query.account_id}`, formData)
        .then(response => {
          commit(REMOVE_LOADING, UPLOAD_VIDEO)
          commit(SET_VIDEO_STATUS_PROCESSING, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, UPLOAD_VIDEO)
          notifyError(error, commit)
        })
    },
    async [GET_VIDEO_STATUS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_VIDEO_STATUS)
      HTTP_VIDEOS().get(`/${query.id}`)
        .then(response => {
          commit(REMOVE_LOADING, GET_VIDEO_STATUS)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_VIDEO_STATUS)
          notifyError(error, commit)
        })
    },
    [FINISH_VIDEO_STATUS_PROCESSING] ({ commit, state, rootState }, data) {
      commit(REMOVE_VIDEO_STATUS_PROCESSING, { data })
    },
    async [GET_AD_CREATIVE] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_AD_CREATIVE)
      const formatParameter = query.format !== undefined ? `format=${query.format}` : ''
      HTTP_ADS().get(`/${query.id}/creative/?${formatParameter}&id=${query.creative_id}`)
        .then(response => {
          commit(REMOVE_LOADING, GET_AD_CREATIVE)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_AD_CREATIVE)
          notifyError(error, commit)
        })
    },
    async [GET_TARGETING_FACEBOOK] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_TARGETING_FACEBOOK)
      HTTP_TARGETING_FACEBOOK().get(`/?type=${query.type}&query=${query.query}${query.class ? `&class=${query.class}` : ''}`)
        .then(response => {
          commit(REMOVE_LOADING, GET_TARGETING_FACEBOOK)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_TARGETING_FACEBOOK)
          notifyError(error, commit)
        })
    },
    async [GET_PAGES_FACEBOOK] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_PAGES_FACEBOOK)
      HTTP_PAGES().get(`/?platform=1&account_id=${query.account_id}`)
        .then(response => {
          commit(REMOVE_LOADING, GET_PAGES_FACEBOOK)
          commit(SET_PAGES_FACEBOOK, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_PAGES_FACEBOOK)
          notifyError(error, commit)
        })
    },
    async [GET_PAGES_INSTAGRAM] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_PAGES_INSTAGRAM)
      HTTP_INSTAGRAM_PAGES().get(`/?platform=1&page_id=${query.page_id}`)
        .then(response => {
          commit(REMOVE_LOADING, GET_PAGES_INSTAGRAM)
          commit(SET_PAGES_INSTAGRAM, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_PAGES_INSTAGRAM)
          notifyError(error, commit)
        })
    },
    async [GET_FACEBOOK_PIXELS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_FACEBOOK_PIXELS)
      HTTP_ADSETS().get(`/${query.id}/pixels`)
        .then(response => {
          commit(REMOVE_LOADING, GET_FACEBOOK_PIXELS)
          commit(SET_FACEBOOK_PIXELS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_FACEBOOK_PIXELS)
          notifyError(error, commit)
        })
    },
    async [GET_FACEBOOK_CUSTOM_EVENTS] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_FACEBOOK_CUSTOM_EVENTS)
      HTTP_ADSETS().get(`/${query.id}/custom_events`)
        .then(response => {
          commit(REMOVE_LOADING, GET_FACEBOOK_CUSTOM_EVENTS)
          commit(SET_FACEBOOK_CUSTOM_EVENTS, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_FACEBOOK_CUSTOM_EVENTS)
          notifyError(error, commit)
        })
    },
    [STORE_FILTER_DATE_FROM] ({ commit, state, rootState }, data) {
      commit(SET_FILTER_DATE_FROM, { data })
    },
    [STORE_FILTER_DATE_TO] ({ commit, state, rootState }, data) {
      commit(SET_FILTER_DATE_TO, { data })
    },
    [STORE_FILTERED_TEAMS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_TEAMS, { data })
    },
    [STORE_FILTERED_AGENCIES] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_AGENCIES, { data })
    },
    [STORE_FILTERED_CLIENTS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_CLIENTS, { data })
    },
    [STORE_FILTERED_TEAM_NAMES] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_TEAM_NAMES, { data })
    },
    [STORE_FILTERED_ORDERS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_ORDERS, { data })
    },
    [STORE_FILTERED_ORDER_NAMES] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_ORDER_NAMES, { data })
    },
    [STORE_FILTERED_LINE_ITEMS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_LINE_ITEMS, { data })
    },
    [STORE_FILTERED_LINE_ITEM_NAMES] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_LINE_ITEM_NAMES, { data })
    },
    [STORE_FILTERED_CAMPAIGNS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_CAMPAIGNS, { data })
    },
    [STORE_FILTERED_ADSETS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_ADSETS, { data })
    },
    [STORE_FILTERED_ADS] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_ADS, { data })
    },
    [STORE_FILTERED_CAMPAIGN_NAMES] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_CAMPAIGN_NAMES, { data })
    },
    [STORE_FILTER_SEARCH_TEXT] ({ commit, state, rootState }, data) {
      commit(SET_FILTERED_SEARCH_TEXT, { data })
    }
  },
  getters: {
    dateFilterFrom: state => state.dateFilterFrom,
    dateFilterTo: state => state.dateFilterTo,
    agencies: state => state.agencies,
    clients: state => state.clients,
    teams: state => state.teams,
    orders: state => state.orders,
    accounts: state => state.accounts,
    campaigns: state => state.campaigns,
    adsets: state => state.adsets,
    ads: state => state.ads,
    campaign: state => state.campaign,
    campaignAdsets: state => state.campaignAdsets,
    targeting: state => state.targeting,
    pages: state => state.pages,
    filteredTeams: state => state.filteredTeams,
    filteredAgencies: state => state.filteredAgencies,
    filteredClients: state => state.filteredClients,
    filteredTeamNames: state => state.filteredTeamNames,
    filteredOrders: state => state.filteredOrders,
    filteredOrderNames: state => state.filteredOrderNames,
    filteredLineItems: state => state.filteredLineItems,
    filteredLineItemNames: state => state.filteredLineItemNames,
    filteredCampaigns: state => state.filteredCampaigns,
    filteredCampaignNames: state => state.filteredCampaignNames,
    filteredAdsets: state => state.filteredAdsets,
    filteredAds: state => state.filteredAds,
    filteredSearchText: state => state.filteredSearchText,
    facebookPixels: state => state.facebookPixels,
    facebookCustomEvents: state => state.facebookCustomEvents,
    videoStatus: state => state.videoStatus
  }
}

// Dynamic module route
import { beforeTemplates } from './route.guard'

export default [
  {
    path: '/templates',
    name: 'templates',
    meta: {
      requiresAuth: true,
      linkText: 'templates',
      imgSrc: '/img/baseline_assignment_white_48dp.png'
    },
    beforeEnter: beforeTemplates,
    component: () => import(/* webpackChunkName: "templates" */ './Templates.vue')
  },
  {
    path: '/templates/new',
    name: 'templates-new',
    meta: {
      requiresAuth: true,
      linkText: 'templates',
      imgSrc: '/img/baseline_assignment_white_48dp.png'
    },
    beforeEnter: beforeTemplates,
    component: () => import(/* webpackChunkName: "templates" */ './NewTemplate.vue')
  }
]

// STORE_ doesn't interact with any API, just stores it
// UPDATE_ sets the value after API interaction
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL_VEIL = 'HIDE_MODAL_VEIL'
export const SHOW_MODAL_VEIL = 'SHOW_MODAL_VEIL'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SIGN_UP = 'SIGN_UP'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const NAVIGATE_TO = 'NAVIGATE_TO'
export const GET_USER_DATA = 'GET_USER_DATA'
export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const GET_ACTIVE_CAMPAIGNS = 'GET_ACTIVE_CAMPAIGNS'
export const SEARCH_CAMPAIGNS = 'SEARCH_CAMPAIGNS'
export const SAVE_NEW_CAMPAIGN = 'SAVE_NEW_CAMPAIGN'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'
export const SAVE_NEW_LINE_ITEM = 'SAVE_NEW_LINE_ITEM'
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM'
export const DELETE_LINE_ITEM = 'DELETE_LINE_ITEM'
export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_ADSETS = 'GET_CAMPAIGN_ADSETS'
export const GET_ADSET_ADS = 'GET_ADSET_ADS'
export const REFRESH_CAMPAIGN = 'REFRESH_CAMPAIGN'
export const REFRESH_ORDER = 'REFRESH_ORDER'
export const REFRESH_LINE_ITEM = 'REFRESH_LINE_ITEM'
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ORDERS = 'GET_ORDERS'
export const SEARCH_ORDERS = 'SEARCH_ORDERS'
export const SAVE_NEW_ORDER = 'SAVE_NEW_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const DELETE_ORDER = 'DELETE_ORDER'
export const GET_OAUTH_FACEBOOK_LINK = 'GET_OAUTH_FACEBOOK_LINK'
export const SAVE_OAUTH_FACEBOOK_TOKEN = 'SAVE_OAUTH_FACEBOOK_TOKEN'
export const FACEBOOK_LOGOUT = 'FACEBOOK_LOGOUT'
export const GET_OAUTH_TWITTER_LINK = 'GET_OAUTH_TWITTER_LINK'
export const SAVE_OAUTH_TWITTER_TOKEN = 'SAVE_OAUTH_TWITTER_TOKEN'
export const TWITTER_LOGOUT = 'TWITTER_LOGOUT'
export const GET_USERS = 'GET_USERS'
export const SAVE_NEW_USER = 'SAVE_NEW_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const GET_AGENCIES = 'GET_AGENCIES'
export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_TEAMS = 'GET_TEAMS'
export const SAVE_NEW_TEAM = 'SAVE_NEW_TEAM'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const DELETE_TEAM = 'DELETE_TEAM'
export const GET_PLATFORMS = 'GET_PLATFORMS'
export const GET_OBJECTIVES = 'GET_OBJECTIVES'
export const GET_BILLING_EVENTS = 'GET_BILLING_EVENTS'
export const GET_OPTIMIZATION_GOALS = 'GET_OPTIMIZATION_GOALS'
export const GET_INSIGHTS = 'GET_INSIGHTS'
export const GET_STATUSES = 'GET_STATUSES'
export const GET_ADSETS = 'GET_ADSETS'
export const REFRESH_ADSET = 'REFRESH_ADSET'
export const SAVE_NEW_ADSET = 'SAVE_NEW_ADSET'
export const UPDATE_ADSET = 'UPDATE_ADSET'
export const DELETE_ADSET = 'DELETE_ADSET'
export const STORE_FILTERED_ADSETS = 'STORE_FILTERED_ADSETS'
export const STORE_FILTERED_ADS = 'STORE_FILTERED_ADS'
export const GET_ADS = 'GET_ADS'
export const REFRESH_AD = 'REFRESH_AD'
export const SAVE_NEW_AD = 'SAVE_NEW_AD'
export const UPDATE_AD = 'UPDATE_AD'
export const DELETE_AD = 'DELETE_AD'
export const SAVE_NEW_CREATIVE = 'SAVE_NEW_CREATIVE'
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO'
export const GET_VIDEO_STATUS = 'GET_VIDEO_STATUS'
export const FINISH_VIDEO_STATUS_PROCESSING = 'FINISH_VIDEO_STATUS_PROCESSING'
export const GET_AD_CREATIVE = 'GET_AD_CREATIVE'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_GENDERS = 'GET_GENDERS'
export const GET_CREATIVE_FORMATS = 'GET_CREATIVE_FORMATS'
export const GET_TARGETING_FACEBOOK = 'GET_TARGETING_FACEBOOK'
export const GET_PAGES_FACEBOOK = 'GET_PAGES_FACEBOOK'
export const GET_PAGES_INSTAGRAM = 'GET_PAGES_INSTAGRAM'
export const STORE_FILTERED_TEAMS = 'STORE_FILTERED_TEAMS'
export const STORE_FILTERED_AGENCIES = 'STORE_FILTERED_AGENCIES'
export const STORE_FILTERED_CLIENTS = 'STORE_FILTERED_CLIENTS'
export const STORE_FILTERED_TEAM_NAMES = 'STORE_FILTERED_TEAM_NAMES'
export const STORE_FILTERED_ORDERS = 'STORE_FILTERED_ORDERS'
export const STORE_FILTERED_ORDER_NAMES = 'STORE_FILTERED_ORDER_NAMES'
export const STORE_FILTERED_LINE_ITEMS = 'STORE_FILTERED_LINE_ITEMS'
export const STORE_FILTERED_LINE_ITEM_NAMES = 'STORE_FILTERED_LINE_ITEM_NAMES'
export const STORE_FILTERED_CAMPAIGNS = 'STORE_FILTERED_CAMPAIGNS'
export const STORE_FILTERED_CAMPAIGN_NAMES = 'STORE_FILTERED_CAMPAIGN_NAMES'
export const GET_TEMPLATES = 'GET_TEMPLATES'
export const SAVE_NEW_TEMPLATE = 'SAVE_NEW_TEMPLATE'
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const GET_REPORT = 'GET_REPORT'
export const GET_REPORTS = 'GET_REPORTS'
export const HIDE_REPORT = 'HIDE_REPORT'
export const SAVE_NEW_REPORT = 'SAVE_NEW_REPORT'
export const DELETE_REPORT = 'DELETE_REPORT'
export const STORE_FILTER_DATE_FROM = 'STORE_FILTER_DATE_FROM'
export const STORE_FILTER_DATE_TO = 'STORE_FILTER_DATE_TO'
export const STORE_FILTER_SEARCH_TEXT = 'STORE_FILTER_SEARCH_TEXT'
export const GET_SPECIAL_AD_CATEGORIES = 'GET_SPECIAL_AD_CATEGORIES'
export const GET_BID_STRATEGIES = 'GET_BID_STRATEGIES'
export const GET_PACING_TYPES = 'GET_PACING_TYPES'
export const REDUCE_CAMPAIGN = 'REDUCE_CAMPAIGN'
export const GET_FB_ESTIMATED_REACH = 'GET_FB_ESTIMATED_REACH'
export const GET_FACEBOOK_PIXELS = 'GET_FACEBOOK_PIXELS'
export const GET_FACEBOOK_CUSTOM_EVENTS = 'GET_FACEBOOK_CUSTOM_EVENTS'
export const GET_CALL_TO_ACTION_VALUES = 'GET_CALL_TO_ACTION_VALUES'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_CONTROLLER_ORDERS = 'GET_CONTROLLER_ORDERS'
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT'
export const GET_FB_CALL_TO_ACTION = 'GET_FB_CALL_TO_ACTION'

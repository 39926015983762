// Dynamic module route
import { beforeDashboard } from './route.guard'

export default [
  {
    path: '/dashboard/:tab',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
      linkText: 'dashboard',
      imgSrc: '/img/baseline_trending_up_white_48dp.png'
    },
    beforeEnter: beforeDashboard,
    component: () => import(/* webpackChunkName: "dashboard" */ './Dashboard.vue')
  }
]

// Dynamic module route
// import { routeGuard } from './route.guard'

export default [
  {
    path: '/team',
    name: 'team',
    meta: {
      requiresAuth: true,
      linkText: 'team',
      imgSrc: '/img/baseline_supervised_user_circle_white_48dp.png'
    },
    // beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "team" */ './Team.vue')
  }
]

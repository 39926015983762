import {
  SIGN_UP
} from '@/core/action-types'
import {
  ADD_LOADING,
  REMOVE_LOADING
} from '@/core/mutation-types'
import {
  HTTP_SIGNUP
} from '@/core/http-common'
import {
  notifyError
} from '@/core/store'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    async [SIGN_UP] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, SIGN_UP)
      const onComplete = query.onComplete
      delete query.onComplete
      HTTP_SIGNUP().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, SIGN_UP)
          onComplete(response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SIGN_UP)
          notifyError(error, commit)
        })
    }
  },
  getters: {
  }
}

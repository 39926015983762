/* eslint-disable camelcase */
export class User {
  constructor ({
    id = null,
    email = null,
    firstName = null,
    lastName = null,
    teamId = null,
    role = null,
    team = null,
    tokens = null,
    fbName = null,
    twName = null
  }) {
    this.id = id
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.teamId = teamId
    this.team = team
    this.role = role
    this.tokens = tokens
    this.fbName = fbName
    this.twName = twName
  }
}

export function createUser (data) {
  return Object.freeze(new User(data))
}

import { createUser } from '@/core/models'
import {
  GET_USER_DATA,
  SIGN_IN,
  SIGN_OUT,
  RESET_PASSWORD,
  CHANGE_PASSWORD
} from '@/core/action-types'
import {
  SET_USER_DATA,
  RESET_USER_FB_TOKEN,
  RESET_USER_TW_TOKEN,
  ADD_LOADING,
  REMOVE_LOADING,
  RESET_APP_STORE,
  RESET_DASHBOARD_STORE
} from '@/core/mutation-types'
import {
  HTTP_SIGNIN,
  HTTP_USERDATA,
  HTTP_SIGNOUT,
  HTTP_RESET_PASSWORD,
  HTTP_CHANGE_PASSWORD
} from '@/core/http-common'
import {
  notifyError,
  notifyInfo
} from '@/core/store'

const commitUserData = (response, commit) => {
  commit(SET_USER_DATA, createUser({
    id: response.data.data.id || response.data.data._id,
    email: response.data.data.email,
    firstName: response.data.data.first_name,
    lastName: response.data.data.last_name,
    teamId: response.data.data.team ? response.data.data.team._id : null,
    team: response.data.data.team,
    role: response.data.data.role,
    tokens: response.data.data.tokens,
    fbName: response.data.data.fb_name,
    twName: response.data.data.tw_name
  }))
  // TODO: Update tokens
  notifyInfo(`User ${response.data.data.email} correctly identified`, commit)
}
const commitSignOut = (commit) => {
  localStorage.removeItem('access_token')
  commit(SET_USER_DATA, null)
  commit(RESET_APP_STORE)
  commit(RESET_DASHBOARD_STORE)
  commit(REMOVE_LOADING, SIGN_OUT)
}

export default {
  state: {
    authData: null
  },
  mutations: {
    [SET_USER_DATA] (state, payload) {
      state.authData = payload
    },
    [RESET_USER_FB_TOKEN] (state) {
      delete state.authData.tokens.facebook
      state.authData = Object.assign({}, { ...state.authData })
    },
    [RESET_USER_TW_TOKEN] (state) {
      delete state.authData.tokens.twitter
      state.authData = Object.assign({}, { ...state.authData })
    }
  },
  actions: {
    async [GET_USER_DATA] ({ commit, state, rootState }) {
      commit(ADD_LOADING, GET_USER_DATA)
      HTTP_USERDATA().get('/', { forceUpdate: true })
        .then(response => {
          commit(REMOVE_LOADING, GET_USER_DATA)
          commitUserData(response, commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_USER_DATA)
          notifyError(error, commit)
        })
    },
    async [SIGN_IN] ({ commit, state, rootState }, { email, password }) {
      commit(ADD_LOADING, SIGN_IN)
      HTTP_SIGNIN().post('/', { email, password }, { forceUpdate: true })
        .then(response => {
          commit(REMOVE_LOADING, SIGN_IN)
          localStorage.setItem('access_token', response.data.token.access_token)
          commitUserData(response, commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SIGN_IN)
          notifyError(error, commit)
        })
    },
    async [SIGN_OUT] ({ commit }) {
      commit(ADD_LOADING, SIGN_OUT)
      HTTP_SIGNOUT().post('/')
        .then(response => {
          commit(REMOVE_LOADING, SIGN_OUT)
          commitSignOut(commit)
        })
        .catch(error => {
          commit(REMOVE_LOADING, SIGN_OUT)
          commitSignOut(commit)
          notifyError(error, commit)
        })
    },
    async [RESET_PASSWORD] ({ commit }, query) {
      commit(ADD_LOADING, RESET_PASSWORD)
      const onComplete = query.onComplete
      delete query.onComplete
      HTTP_RESET_PASSWORD().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, RESET_PASSWORD)
          onComplete(response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, RESET_PASSWORD)
          notifyError(error, commit)
        })
    },
    async [CHANGE_PASSWORD] ({ commit }, query) {
      commit(ADD_LOADING, CHANGE_PASSWORD)
      const onComplete = query.onComplete
      delete query.onComplete
      HTTP_CHANGE_PASSWORD().post('/', query)
        .then(response => {
          commit(REMOVE_LOADING, CHANGE_PASSWORD)
          onComplete(response.data)
        })
        .catch(error => {
          commit(REMOVE_LOADING, CHANGE_PASSWORD)
          notifyError(error, commit)
        })
    }
  },
  getters: {
    authData: state => state.authData
  }
}

export const SET_USER_DATA = 'SET_USER_DATA'
export const RESET_USER_FB_TOKEN = 'RESET_USER_FB_TOKEN'
export const RESET_USER_TW_TOKEN = 'RESET_USER_TW_TOKEN'
export const SET_APP_PATH = 'SET_APP_PATH'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_MODAL = 'SET_MODAL'
export const SET_MODAL_VEIL = 'SET_MODAL_VEIL'
export const ADD_LOADING = 'ADD_LOADING'
export const REMOVE_LOADING = 'REMOVE_LOADING'
export const SET_ORDERS = 'SET_ORDERS'
export const SET_FOUND_ORDERS = 'SET_FOUND_ORDERS'
export const ADD_ORDER = 'ADD_ORDER'
export const SET_ORDER = 'SET_ORDER'
export const REMOVE_ORDER = 'REMOVE_ORDER'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM'
export const SET_LINE_ITEM = 'SET_LINE_ITEM'
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM'
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS'
export const SET_FOUND_CAMPAIGNS = 'SET_FOUND_CAMPAIGNS'
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN'
export const SET_CAMPAIGN = 'SET_CAMPAIGN'
export const REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN'
export const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA'
export const SET_CAMPAIGN_ADSETS = 'SET_CAMPAIGN_ADSETS'
export const SET_OAUTH_FACEBOOK_LINK = 'SET_OAUTH_FACEBOOK_LINK'
export const SET_OAUTH_FACEBOOK_TOKEN = 'SET_OAUTH_FACEBOOK_TOKEN'
export const SET_OAUTH_TWITTER_LINK = 'SET_OAUTH_TWITTER_LINK'
export const SET_OAUTH_TWITTER_TOKEN = 'SET_OAUTH_TWITTER_TOKEN'
export const SET_USERS = 'SET_USERS'
export const ADD_USER = 'ADD_USER'
export const SET_USER = 'SET_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const SET_AGENCIES = 'SET_AGENCIES'
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_TEAMS = 'SET_TEAMS'
export const ADD_TEAM = 'ADD_TEAM'
export const SET_TEAM = 'SET_TEAM'
export const REMOVE_TEAM = 'REMOVE_TEAM'
export const SET_PLATFORMS = 'SET_PLATFORMS'
export const SET_OBJECTIVES = 'SET_OBJECTIVES'
export const SET_BILLING_EVENTS = 'SET_BILLING_EVENTS'
export const SET_OPTIMIZATION_GOALS = 'SET_OPTIMIZATION_GOALS'
export const SET_INSIGHTS = 'SET_INSIGHTS'
export const SET_STATUSES = 'SET_STATUSES'
export const SET_ADSETS = 'SET_ADSETS'
export const ADD_ADSET = 'ADD_ADSET'
export const SET_ADSET = 'SET_ADSET'
export const SET_ADSET_DATA = 'SET_ADSET_DATA'
export const REMOVE_ADSET = 'REMOVE_ADSET'
export const SET_FILTERED_ADSETS = 'SET_FILTERED_ADSETS'
export const SET_FILTERED_ADS = 'SET_FILTERED_ADS'
export const SET_ADS = 'SET_ADS'
export const SET_AD = 'SET_AD'
export const SET_AD_DATA = 'SET_AD_DATA'
export const ADD_AD = 'ADD_AD'
export const REMOVE_AD = 'REMOVE_AD'
// export const ADD_CREATIVE = 'ADD_CREATIVE'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_GENDERS = 'SET_GENDERS'
export const SET_CREATIVE_FORMATS = 'SET_CREATIVE_FORMATS'
export const SET_TARGETING_FACEBOOK = 'SET_TARGETING_FACEBOOK'
export const SET_PAGES_FACEBOOK = 'SET_PAGES_FACEBOOK'
export const SET_PAGES_INSTAGRAM = 'SET_PAGES_INSTAGRAM'
export const SET_FILTERED_TEAMS = 'SET_FILTERED_TEAMS'
export const SET_FILTERED_AGENCIES = 'SET_FILTERED_AGENCIES'
export const SET_FILTERED_CLIENTS = 'SET_FILTERED_CLIENTS'
export const SET_FILTERED_TEAM_NAMES = 'SET_FILTERED_TEAM_NAMES'
export const SET_FILTERED_ORDERS = 'SET_FILTERED_ORDERS'
export const SET_FILTERED_ORDER_NAMES = 'SET_FILTERED_ORDER_NAMES'
export const SET_FILTERED_LINE_ITEMS = 'SET_FILTERED_LINE_ITEMS'
export const SET_FILTERED_LINE_ITEM_NAMES = 'SET_FILTERED_LINE_ITEM_NAMES'
export const SET_FILTERED_CAMPAIGNS = 'SET_FILTERED_CAMPAIGNS'
export const SET_FILTERED_CAMPAIGN_NAMES = 'SET_FILTERED_CAMPAIGN_NAMES'
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const SET_TEMPLATE = 'SET_TEMPLATE'
export const AD_TEMPLATE = 'AD_TEMPLATE'
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE'
export const SET_REPORTS = 'SET_REPORTS'
export const SET_REPORT = 'SET_REPORT'
// export const AD_REPORT = 'AD_REPORT'
export const REMOVE_REPORT = 'REMOVE_REPORT'
export const SET_FILTER_DATE_FROM = 'SET_FILTER_DATE_FROM'
export const SET_FILTER_DATE_TO = 'SET_FILTER_DATE_TO'
export const SET_FILTERED_SEARCH_TEXT = 'SET_FILTERED_SEARCH_TEXT'
export const SET_SPECIAL_AD_CATEGORIES = 'SET_SPECIAL_AD_CATEGORIES'
export const SET_BID_STRATEGIES = 'SET_BID_STRATEGIES'
export const SET_PACING_TYPES = 'SET_PACING_TYPES'
export const SET_FB_ESTIMATED_REACH = 'SET_FB_ESTIMATED_REACH'
export const SET_FACEBOOK_PIXELS = 'SET_FACEBOOK_PIXELS'
export const SET_FACEBOOK_CUSTOM_EVENTS = 'SET_FACEBOOK_CUSTOM_EVENTS'
export const SET_CALL_TO_ACTION_VALUES = 'SET_CALL_TO_ACTION_VALUES'
export const SET_VIDEO_STATUS_PROCESSING = 'SET_VIDEO_STATUS_PROCESSING'
export const REMOVE_VIDEO_STATUS_PROCESSING = 'REMOVE_VIDEO_STATUS_PROCESSING'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_CONTROLLER_ORDERS = 'SET_CONTROLLER_ORDERS'
export const RESET_APP_STORE = 'RESET_APP_STORE'
export const RESET_DASHBOARD_STORE = 'RESET_DASHBOARD_STORE'
export const SET_FB_CALL_TO_ACTION = 'SET_FB_CALL_TO_ACTION'

import Vue from 'vue'
import Router from 'vue-router'
import loginRoute from '@/modules/login/route'
import signupRoute from '@/modules/signup/route'
import dashboardRoute from '@/modules/dashboard/route'
import campaignRoute from '@/modules/campaign/route'
import templatesRoute from '@/modules/templates/route'
import reportsRoute from '@/modules/reports/route'
import profileRoute from '@/modules/profile/route'
import teamRoute from '@/modules/team/route'
import usersTeamsRoute from '@/modules/users-teams/route'
import settingsRoute from '@/modules/settings/route'
import controllerRoute from '@/modules/controller/route'
import nonexistingRoute from '@/modules/non-existing/route'

const managerRoute = [{
  path: '/manager',
  name: 'manager',
  meta: {
    linkText: 'manager',
    imgSrc: '/img/baseline_assignment_turned_in_white_48dp.png',
    alertImgSrc: '/img/baseline_assignment_late_white_48dp.png'
  }
}]

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'nav__link--active',
  routes: [
    ...loginRoute,
    ...signupRoute,
    ...profileRoute,
    ...teamRoute,
    ...usersTeamsRoute,
    ...settingsRoute,
    ...dashboardRoute,
    ...campaignRoute,
    ...managerRoute,
    ...templatesRoute,
    ...reportsRoute,
    ...controllerRoute,
    // TODO: Add error handling route & component
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
    // Matches all other routes:
    ...nonexistingRoute
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('access_token') === null) {
      next({
        path: '/signin',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

import {
  GET_CONTROLLER_ORDERS
} from '@/core/action-types'
import {
  ADD_LOADING,
  REMOVE_LOADING,
  SET_CONTROLLER_ORDERS
} from '@/core/mutation-types'
import {
  HTTP_CONTROLLER_ORDERS
} from '@/core/http-common'
import {
  notifyError
  // ,
  // notifySuccess
} from '@/core/store'

export default {
  state: {
    controllerOrders: []
  },
  mutations: {
    [SET_CONTROLLER_ORDERS] (state, payload) {
      state.controllerOrders = payload.data
    }
  },
  actions: {
    async [GET_CONTROLLER_ORDERS] ({ dispatch, commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_CONTROLLER_ORDERS)
      // HTTP_CONTROLLER_ORDERS().post('/', query)
      //   .then(response => {
      //     commit(REMOVE_LOADING, GET_CONTROLLER_ORDERS)
      //     commit(SET_CONTROLLER_ORDERS, response.data)
      //     if (query && query.onComplete && typeof query.onComplete === 'function') {
      //       query.onComplete(response.data)
      //     }
      //   })
      //   .catch(error => {
      //     commit(REMOVE_LOADING, GET_CONTROLLER_ORDERS)
      //     notifyError(error, commit)
      //   })
      console.log(HTTP_CONTROLLER_ORDERS, notifyError)
      commit(REMOVE_LOADING, GET_CONTROLLER_ORDERS)
      commit(SET_CONTROLLER_ORDERS, { data: [] })
    }
  },
  getters: {
    controllerOrders: state => state.controllerOrders
  }
}

import store from '@/core/store'
import {
  SIGN_OUT
} from '@/core/action-types'

// Route guards
export function beforeSignIn (to, from, next) {
  next()
}
export function beforeSignOut (to, from, next) {
  store.dispatch(SIGN_OUT)
  next()
}
export function beforeSignUp (to, from, next) {
  next()
}
export function beforePasswordReset (to, from, next) {
  next()
}

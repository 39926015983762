<template>
  <div class="modal">
    <div class="card card__modal" :class="[ modalData.form && modalData.form.modalClass ? modalData.form.modalClass : '' ]">
      <!--
        // USO:
        const stepsTitle = [
          { value: '1', label: this.$t('new-planning'), status: 'active' },
          { value: '2', label: this.$t('new-planning-line'), status: 'inactive' }
        ]
      -->
      <div class="card__header card__header--with-steps" v-if="modalData.form.steps">
        <h4 v-for="(step, index) in modalData.form.steps" :key="index" :class="`card__header-step card__header-step--${step.status}`">
          <span class="step-value">{{ step.value }}</span>
          <span class="step-label">{{ step.label }}</span>
        </h4>
      </div>
      <div class="card__header card__header--with-title" v-else>
        <h4 class="card__title">{{ modalData.title }}</h4>
        <button class="btn btn--close-modal" @click.prevent="hideModal">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('cancel')" :title="$t('cancel')" class="btn__img btn__img--small">
        </button>
      </div>
      <div class="card__content">
        <form v-if="modalData.form"
          class="form__modal"
          :class="[ modalData.form.tabs ? 'form__modal--with-tabs' : '' ]"
          autocomplete="off"
          action="#save"
          id="modal-form"
          ref="modal-form"
          v-on:submit.stop
          v-on:keyup.esc="hideModal">
          <input autocomplete="false" name="hidden" type="text" style="display:none;">
          <input v-for="field in modalData.form.hiddenFields" :key="field.name" :id="field.name" :name="field.name" type="hidden" :value="field.value">
          <template v-if="!modalData.form.tabs">
            <form-field v-for="field in modalData.form.fields" :key="field.name" :fielddata="field" v-on:change="onFieldChange"></form-field>
            <dl v-if="modalData.form.formTextList && modalData.form.formTextList.length > 0" class="form_text-list">
              <template v-for="(item, itemIndex) in modalData.form.formTextList">
                <dt :key="`${itemIndex}-dt`" class="form_text-list__dt">{{ item.dt }}:</dt>
                <dd v-for="(ddItem, ddIndex) in item.dd" :key="`${itemIndex}-${ddIndex}-dd`" class="form_text-list__dd">{{ ddItem }}</dd>
              </template>
            </dl>
          </template>
          <template v-else>
            <input v-for="(item, itemIndex) in modalData.form.tabs"
              :key="itemIndex"
              v-model="tabsList"
              :value="itemIndex"
              :class="[ `tabs__tab-state${item.classSuffix}` ]"
              :title="item.label"
              :id="item.id"
              :ref="item.id"
              name="modal-tabs-state"
              class="tabs__tab-state" type="radio" />
            <div
              :class="[ `--tab-items-${modalData.form.tabs.length}` ]"
              class="tabs">
              <label v-for="(item, itemIndex) in modalData.form.tabs"
                :key="`${itemIndex}-label`"
                :for="item.id"
                :id="`${item.id}-label`"
                :class="[ `tabs__tab-label${item.classSuffix}`, tabsList === itemIndex ? 'tabs__tab-button--checked' : '' ]"
                class="tabs__tab-button">{{ item.label }}</label>
              <div v-for="(item, itemIndex) in modalData.form.tabs"
                :key="`${itemIndex}-panel`"
                :id="`tab-${item.id}-panel`"
                :class="[ `tabs__tab-content${item.classSuffix}`, tabsList === itemIndex ? 'tabs__tab-content--checked' : '' ]"
                class="tabs__tab-content">
                <div class="tabs__tab-content-wrapper">
                  <form-field v-for="field in filterTabContent(modalData.form.fields, item.id)" :key="field.name" :fielddata="field" v-on:change="onFieldChange"></form-field>
                  <dl v-if="modalData.form.formTextList && filterTabContent(modalData.form.formTextList).length > 0" class="form_text-list">
                    <template v-for="(item, itemIndex) in filterTabContent(modalData.form.formTextList)">
                      <dt :key="`${itemIndex}-dt`" class="form_text-list__dt">{{ item.dt }}:</dt>
                      <dd v-for="(ddItem, ddIndex) in item.dd" :key="`${itemIndex}-${ddIndex}-dd`" class="form_text-list__dd">{{ ddItem }}</dd>
                    </template>
                  </dl>
                </div>
              </div>
            </div>
          </template>
        </form>
        <!-- TODO: ÉSTO NO VA BIEN, HAY QUE PENSAR OTRO FORMATO -->
        <div v-if="modalData.form && modalData.form.iframe" class="iframe__modal" ref="iframe-modal"></div>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button @click="hideModal" class="btn btn--text">{{ $t('cancel') }}</button>
          </div>
          <div class="modal__actions--right">
            <button v-if="modalData.form && modalData.form.saveDelete" type="button" form="modal-form" class="btn btn--new-secondary" @click="modalData.form.saveDelete(getQueryFromFields())">{{ $t('delete') }}</button>
            <button v-if="modalData.form && modalData.form.newItem"
              v-on:click="modalData.form.newItem()"
              form="modal-form" class="btn btn--new">{{ `${$t('new')} ${$t(modalData.form.newItemButton)}` }}</button>
            <button v-if="modalData.form && modalData.form.secondaryLinkAction"
              v-on:click="goToSecondaryLink"
              type="button" form="modal-form" class="btn btn--new-secondary">{{ modalData.form.secondaryLinkText }}</button>
            <button v-if="modalData.form && modalData.form.save"
              v-on:click="submitForm"
              type="button" form="modal-form" class="btn btn--new">{{ modalData.form.defaultSaveButtonName ? modalData.form.defaultSaveButtonName : $t('create') }}</button>
            <button v-if="modalData.form && modalData.form.saveAndContinue"
              v-on:click="saveAndContinue"
              type="button" form="modal-form" class="btn btn--new-secondary">{{ modalData.form.saveAndContinueButton }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions
} from 'vuex'
import {
  HIDE_MODAL
} from '@/core/action-types'
import FormField from '@/core/components/FormField.vue'
export default {
  name: 'modal',
  components: {
    FormField
  },
  props: [
    'modalData'
  ],
  data () {
    return {
      // Default selected tab
      tabsList: 0
    }
  },
  // TODO: hacer algún día:
  // :class="[ saveDisabled ? 'btn--disabled' : '' ]"
  // :disabled="saveDisabled"
  // computed: {
  //   saveDisabled () {
  //     return !this.$refs['modal-form'] ? false : !this.$refs['modal-form'].checkValidity()
  //   }
  // },
  mounted () {
    if (this.$refs['modal-form'] && this.$refs['modal-form'].querySelector('input[class]')) {
      this.$refs['modal-form'].querySelector('input[class]').focus()
    }
  },
  methods: {
    ...mapActions({
      hideModal: HIDE_MODAL
    }),
    onFieldChange: function (data) {
      const field = this.modalData.form.fields.find(item => item.name === data.field)
      field.value = data.value
      if (field.onChange) {
        field.onChange.bind(this)(data, this.modalData.form)
      }
    },
    getQueryFromFields: function () {
      const returnValue = {}

      if (this.modalData.form.fields) {
        this.modalData.form.fields.forEach(field => {
          if (field.type === 'select' && field.value === '') {
            returnValue[field.name] = 'null'
          } else {
            returnValue[field.name] = field.value
          }
        })
      }
      if (this.modalData.form.hiddenFields) {
        this.modalData.form.hiddenFields.forEach(field => {
          returnValue[field.name] = field.value
        })
      }

      return returnValue
    },
    submitForm () {
      if (this.modalData.form.tabs) {
        // When used on elements/components with v-for, the registered reference will be an Array containing DOM nodes or component instances.
        this.$refs[this.modalData.form.tabs[0].id][0].click()
      }
      if (this.$refs['modal-form'].checkValidity()) {
        this.modalData.form.save(this.getQueryFromFields())
      } else {
        this.$refs['modal-form'].reportValidity()
      }
    },
    saveAndContinue () {
      if (this.modalData.form.tabs) {
        // When used on elements/components with v-for, the registered reference will be an Array containing DOM nodes or component instances.
        this.$refs[this.modalData.form.tabs[0].id][0].click()
      }
      if (this.$refs['modal-form'].checkValidity()) {
        this.modalData.form.saveAndContinue(this.getQueryFromFields())
      } else {
        this.$refs['modal-form'].reportValidity()
      }
    },
    goToSecondaryLink () {
      this.modalData.form.secondaryLinkAction(this.getQueryFromFields())
    },
    filterTabContent (items, tabId) {
      return items.filter(item => item.tab === tabId)
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
.modal {
  position: fixed;
  /* background-color: rgba(0, 0, 0, 0.28); */
  padding: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-content: center;
}
.card__modal {
  width: 100%;
  max-width: calc(100% - 20px);
  min-height: 300px;
  margin: 60px auto auto;
  display: grid;
  padding: 30px;
  border-radius: 30px;
  grid-template-rows: 60px 1fr;
  overflow: visible;
  background-color: var(--app-white);
}
.card__title {
  font-size: 24px;
  line-height: 30px;
  height: 30px;
  font-weight: var(--app-font-bold);
}
.card__content {
  padding: 0;
}
.form__modal {
  margin: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form__modal--with-tabs {
  margin: 0;
}
.iframe__modal {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  height: 400px;
  width: 780px;
  overflow-y: auto;
}
.tabs__tab-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}
.tabs {
  flex-basis: 100%;
  /* grid-template-rows: 30px 1fr; */
  grid-template-rows: 50px 1fr;
  grid-row-gap: 20px;
}
.tabs__tab-button {
  background-color: var(--app-light-purple);
}
.--with-subtitle {
  grid-template-rows: 64px 1fr 50px;
}
@media (min-width: 460px) {
  .card__modal {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 800px) {
  .card__modal {
    /* max-width: 800px; */
    max-width: 540px;
  }
  .card__modal--large {
    max-width: 800px;
  }
}
.modal__actions {
  margin-top: 0px;
}
</style>

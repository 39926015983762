import {
  GET_FB_ESTIMATED_REACH,
  GET_FB_CALL_TO_ACTION
} from '@/core/action-types'
import {
  ADD_LOADING,
  REMOVE_LOADING,
  SET_FB_ESTIMATED_REACH,
  SET_FB_CALL_TO_ACTION
} from '@/core/mutation-types'
import {
  HTTP_FB_ESTIMATED_REACH,
  HTTP_FB_CALL_TO_ACTION
} from '@/core/http-common'
import {
  notifyError
} from '@/core/store'

export default {
  state: {
    audience: {},
    callToAction: {}
  },
  mutations: {
    [SET_FB_ESTIMATED_REACH] (state, payload) {
      // FORMAT:
      // {
      //   "api_id": "14176469070",
      //   "name": "Filmax",
      //   "platform": 1
      // }
      state.audience = {
        ...state.audience,
        facebook: payload.data
      }
    },
    [SET_FB_CALL_TO_ACTION] (state, payload) {
      // FORMAT:
      // {
      //   "name": "Filmax"
      // }
      state.callToAction = {
        ...state.callToAction,
        facebook: payload.data
      }
    }
  },
  actions: {
    async [GET_FB_ESTIMATED_REACH] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_FB_ESTIMATED_REACH)
      // returns: {"data":{"users":29000000,"estimate_ready":true}}
      HTTP_FB_ESTIMATED_REACH().post('1', query)
        .then(response => {
          commit(REMOVE_LOADING, GET_FB_ESTIMATED_REACH)
          commit(SET_FB_ESTIMATED_REACH, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_FB_ESTIMATED_REACH)
          notifyError(error, commit)
        })
    },
    async [GET_FB_CALL_TO_ACTION] ({ commit, state, rootState }, query) {
      commit(ADD_LOADING, GET_FB_CALL_TO_ACTION)
      HTTP_FB_CALL_TO_ACTION().get()
        .then(response => {
          commit(REMOVE_LOADING, GET_FB_CALL_TO_ACTION)
          commit(SET_FB_CALL_TO_ACTION, response.data)
          if (query && query.onComplete && typeof query.onComplete === 'function') {
            query.onComplete(response.data)
          }
        })
        .catch(error => {
          commit(REMOVE_LOADING, GET_FB_CALL_TO_ACTION)
          notifyError(error, commit)
        })
    }
  },
  getters: {
    audience: state => state.audience,
    callToAction: state => state.callToAction
  }
}

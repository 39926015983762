<template>
  <button v-if="!config.isHidden" class="btn btn--icon btn--square btn--with-menu" :title="config.title" :id="config.id" @click="isChildrenVisible = !isChildrenVisible">
    <span class="btn__badge" v-if="config.userNotifications > 0">{{ config.userNotifications }}</span>
    <img class="btn__img" :src="config.icon">
    <ol class="btn__menu" v-if="config.children && isChildrenVisible">
      <li class="btn__menu__item" v-for="(child, index) in config.children" :key="index">
        <router-link :to="child.route" class="btn__menu__item__link" :title="child.title" exact  @click="isChildrenVisible = false">
          <img v-if="child.icon"  class="btn__menu__item__link_icon" :src="child.icon" />{{ child.title }}
        </router-link>
      </li>
    </ol>
  </button>
</template>
<script>
export default {
  name: 'button-with-menu',
  props: [
    'config'
  ],
  data: function () {
    return {
      isChildrenVisible: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped>

</style>

import Vue from 'vue'
import Vuex from 'vuex'
import dashboardStore from '@/modules/dashboard/store'
import campaignStore from '@/modules/campaign/store'
import loginStore from '@/modules/login/store'
import signUpStore from '@/modules/signup/store'
import teamStore from '@/modules/team/store'
import usersTeamsStore from '@/modules/users-teams/store'
import profileStore from '@/modules/profile/store'
import templatesStore from '@/modules/templates/store'
import reportsStore from '@/modules/reports/store'
import controllerStore from '@/modules/controller/store'
import {
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
  HIDE_MODAL,
  SHOW_MODAL,
  HIDE_MODAL_VEIL,
  SHOW_MODAL_VEIL,
  NAVIGATE_TO,
  START_LOADING,
  END_LOADING,
  GET_PLATFORMS,
  GET_OBJECTIVES,
  GET_BILLING_EVENTS,
  GET_OPTIMIZATION_GOALS,
  GET_PACING_TYPES,
  GET_INSIGHTS,
  GET_STATUSES,
  GET_COUNTRIES,
  GET_GENDERS,
  GET_CREATIVE_FORMATS,
  GET_SPECIAL_AD_CATEGORIES,
  GET_BID_STRATEGIES,
  GET_CALL_TO_ACTION_VALUES
} from '@/core/action-types'
import {
  SET_APP_PATH,
  SET_NOTIFICATION,
  SET_MODAL,
  SET_MODAL_VEIL,
  ADD_LOADING,
  REMOVE_LOADING,
  SET_PLATFORMS,
  SET_OBJECTIVES,
  SET_BILLING_EVENTS,
  SET_OPTIMIZATION_GOALS,
  SET_PACING_TYPES,
  SET_INSIGHTS,
  SET_STATUSES,
  SET_COUNTRIES,
  SET_GENDERS,
  SET_CREATIVE_FORMATS,
  SET_SPECIAL_AD_CATEGORIES,
  SET_BID_STRATEGIES,
  SET_CALL_TO_ACTION_VALUES,
  RESET_APP_STORE
} from '@/core/mutation-types'
import {
  HTTP_COUNTRIES
} from '@/core/http-common'
Vue.use(Vuex)

export const notifyError = (error, commit) => {
  let message = ''
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log('The request was made and the server responded with a status code')
    console.log('error.response.data', error.response.data)
    console.log('error.response.status', error.response.status)
    console.log('error.response.headers', error.response.headers)
    if (error.response.data && error.response.data.error) {
      if (error.response.data.error.errors) {
        message = Object.values(error.response.data.error.errors).join(' ')
      } else if (error.response.data.error.message) {
        message = error.response.data.error.message
      } else {
        message = 'ERROR NO DEFINIDO EN response.data!!!'
      }
    } else {
      message = `ERROR (${error.response.status}) sin response.data!!!`
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('The request was made but no response was received')
    console.log('error.request', error.request)
    message = error.request
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Something happened in setting up the request that triggered an Error')
    console.log('error.message', error.message)
    message = error.message
  }
  console.log('error.config', error.config)
  commit(SET_NOTIFICATION, {
    text: message,
    type: 'error',
    icon: 'error'
  })
}
export const notifyInfo = (text, commit) => {
  commit(SET_NOTIFICATION, {
    text,
    type: 'info',
    icon: 'info'
  })
}
export const notifySuccess = (text, commit) => {
  commit(SET_NOTIFICATION, {
    text,
    type: 'success',
    icon: 'success'
  })
}

const platforms = [
  { label: 'Facebook', value: 1 },
  { label: 'Instagram', value: 2 },
  { label: 'Google', value: 3 },
  { label: 'Twitter', value: 4 },
  { label: 'Linkedin', value: 5 }
]
const objectives = [
  { label: 'Reach', value: 'REACH' },
  { label: 'Conversions', value: 'CONVERSIONS' },
  { label: 'App Installs', value: 'APP_INSTALLS' },
  { label: 'Lead generation', value: 'LEAD_GENERATION' },
  { label: 'Engagement', value: 'POST_ENGAGEMENT' },
  { label: 'Messages sent', value: 'MESSAGES' },
  { label: 'Brand awareness', value: 'BRAND_AWARENESS' },
  { label: 'Video Views', value: 'VIDEO_VIEWS' },
  { label: 'Link clicks', value: 'LINK_CLICKS' },
  { label: 'Business traffic', value: 'STORE_VISITS' },
  { label: 'Product catalog sales', value: 'PRODUCT_CATALOG_SALES' }
]
const billingEvents = [
  { label: 'LINK_CLICKS', value: 'LINK_CLICKS' },
  { label: 'IMPRESSIONS', value: 'IMPRESSIONS' }
]
const optimizationGoals = [
  { label: 'IMPRESSIONS', value: 'IMPRESSIONS' },
  { label: 'REACH', value: 'REACH' },
  { label: 'LINK_CLICKS', value: 'LINK_CLICKS' },
  { label: 'CONVERSIONS', value: 'CONVERSIONS' },
  { label: 'PURCHASES', value: 'PURCHASES' },
  { label: 'LEAD_GENERATION', value: 'LEAD_GENERATION' }
]
const insights = [
  { label: 'Impressions', value: 'impressions' },
  { label: 'Reach', value: 'reach' },
  { label: 'Link clicks', value: 'link_clicks' },
  { label: 'Spend - Buying cost', value: 'spend' },
  { label: 'Spend', value: 'selling_cost' },
  { label: 'Conversions', value: 'cv_offsite_conversion_*' },
  { label: 'Clicks (all)', value: 'clicks' },
  { label: 'Shares', value: 'cv_posts' },
  { label: 'Leads', value: 'cv_leads' },
  { label: 'Complete registrations', value: 'cv_omni_complete_registrations' },
  { label: 'Post engagements', value: 'cv_post_engagements' },
  { label: 'Reactions', value: 'cv_post_reactions' },
  { label: 'App installs', value: 'cv_app_installs' },
  { label: 'Comments', value: 'cv_comments' },
  { label: 'Unique clicks', value: 'unique_clicks' },
  { label: 'Video views', value: 'vi_video_views' },
  { label: 'Thruplay views', value: 'vi_thruplay_views' },
  { label: '2 second continuous video views', value: 'vi_2_sec_video_views' },
  { label: 'Registrations', value: 'cv_omni_complete_registrations' }
]
const statuses = [
  { label: 'Programmed', value: 'PROGRAMMED' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Paused', value: 'PAUSED' },
  { label: 'Finalized', value: 'FINALIZED' },
  { label: 'Billed', value: 'BILLED' }
]
const genders = [
  { label: 'All', value: 'ALL' },
  { label: 'Men', value: 'MEN' },
  { label: 'Women', value: 'WOMEN' }
]
const specialAdCategories = [
  { label: 'NONE', value: 'NONE' },
  { label: 'EMPLOYMENT', value: 'EMPLOYMENT' },
  { label: 'HOUSING', value: 'HOUSING' },
  { label: 'CREDIT', value: 'CREDIT' },
  { label: 'ISSUES_ELECTIONS_POLITICS', value: 'ISSUES_ELECTIONS_POLITICS' }
]
const bidStrategies = [
  { label: 'LOWEST_COST_WITHOUT_CAP', value: 'LOWEST_COST_WITHOUT_CAP' },
  { label: 'LOWEST_COST_WITH_BID_CAP', value: 'LOWEST_COST_WITH_BID_CAP' },
  // This value is documented on campaign-budget-optimization but never used
  // LINK: https://developers.facebook.com/docs/marketing-api/bidding/guides/campaign-budget-optimization
  // { label: 'LOWEST_COST_WITH_MIN_ROAS', value: 'LOWEST_COST_WITH_MIN_ROAS' },
  { label: 'COST_CAP', value: 'COST_CAP' }
]
const pacingTypes = [
  { label: 'standard', value: 'standard' },
  { label: 'no_pacing', value: 'no_pacing' },
  { label: 'day_parting', value: 'day_parting' }
]
// LINK: https://developers.facebook.com/docs/marketing-api/reference/ad-creative-link-data-call-to-action/
// TODO: Check if the objective is finally needed, and ad this to Ad.vue filteredCallToActionValues:
// .map(item => {
//   return item.objective.includes(this.campaign.objective) ? item : false
// })
// { label: 'OPEN_LINK', value: 'OPEN_LINK', objective: ['LINK_CLICKS'] },
const callToActionValues = [
  { label: 'OPEN_LINK', value: 'OPEN_LINK' },
  { label: 'LIKE_PAGE', value: 'LIKE_PAGE' },
  { label: 'SHOP_NOW', value: 'SHOP_NOW' },
  { label: 'PLAY_GAME', value: 'PLAY_GAME' },
  { label: 'INSTALL_APP', value: 'INSTALL_APP' },
  { label: 'USE_APP', value: 'USE_APP' },
  { label: 'CALL', value: 'CALL' },
  { label: 'CALL_ME', value: 'CALL_ME' },
  { label: 'VIDEO_CALL', value: 'VIDEO_CALL' },
  { label: 'INSTALL_MOBILE_APP', value: 'INSTALL_MOBILE_APP' },
  { label: 'USE_MOBILE_APP', value: 'USE_MOBILE_APP' },
  { label: 'MOBILE_DOWNLOAD', value: 'MOBILE_DOWNLOAD' },
  { label: 'BOOK_TRAVEL', value: 'BOOK_TRAVEL' },
  { label: 'LISTEN_MUSIC', value: 'LISTEN_MUSIC' },
  { label: 'WATCH_VIDEO', value: 'WATCH_VIDEO' },
  { label: 'LEARN_MORE', value: 'LEARN_MORE' },
  { label: 'SIGN_UP', value: 'SIGN_UP' },
  { label: 'DOWNLOAD', value: 'DOWNLOAD' },
  { label: 'WATCH_MORE', value: 'WATCH_MORE' },
  { label: 'NO_BUTTON', value: 'NO_BUTTON' },
  { label: 'VISIT_PAGES_FEED', value: 'VISIT_PAGES_FEED' },
  { label: 'APPLY_NOW', value: 'APPLY_NOW' },
  { label: 'CONTACT', value: 'CONTACT' },
  { label: 'BUY_NOW', value: 'BUY_NOW' },
  { label: 'GET_OFFER', value: 'GET_OFFER' },
  { label: 'GET_OFFER_VIEW', value: 'GET_OFFER_VIEW' },
  { label: 'BUY_TICKETS', value: 'BUY_TICKETS' },
  { label: 'UPDATE_APP', value: 'UPDATE_APP' },
  { label: 'GET_DIRECTIONS', value: 'GET_DIRECTIONS' },
  { label: 'BUY', value: 'BUY' },
  { label: 'MESSAGE_PAGE', value: 'MESSAGE_PAGE' },
  { label: 'DONATE', value: 'DONATE' },
  { label: 'SUBSCRIBE', value: 'SUBSCRIBE' },
  { label: 'SAY_THANKS', value: 'SAY_THANKS' },
  { label: 'SELL_NOW', value: 'SELL_NOW' },
  { label: 'SHARE', value: 'SHARE' },
  { label: 'DONATE_NOW', value: 'DONATE_NOW' },
  { label: 'GET_QUOTE', value: 'GET_QUOTE' },
  { label: 'CONTACT_US', value: 'CONTACT_US' },
  { label: 'ORDER_NOW', value: 'ORDER_NOW' },
  { label: 'START_ORDER', value: 'START_ORDER' },
  { label: 'ADD_TO_CART', value: 'ADD_TO_CART' },
  { label: 'VIDEO_ANNOTATION', value: 'VIDEO_ANNOTATION' },
  { label: 'MOMENTS', value: 'MOMENTS' },
  { label: 'RECORD_NOW', value: 'RECORD_NOW' },
  { label: 'REFER_FRIENDS', value: 'REFER_FRIENDS' },
  { label: 'REQUEST_TIME', value: 'REQUEST_TIME' },
  { label: 'GET_SHOWTIMES', value: 'GET_SHOWTIMES' },
  { label: 'LISTEN_NOW', value: 'LISTEN_NOW' },
  { label: 'WOODHENGE_SUPPORT', value: 'WOODHENGE_SUPPORT' },
  { label: 'SOTTO_SUBSCRIBE', value: 'SOTTO_SUBSCRIBE' },
  { label: 'FOLLOW_USER', value: 'FOLLOW_USER' },
  { label: 'EVENT_RSVP', value: 'EVENT_RSVP' },
  { label: 'WHATSAPP_MESSAGE', value: 'WHATSAPP_MESSAGE' },
  { label: 'FOLLOW_NEWS_STORYLINE', value: 'FOLLOW_NEWS_STORYLINE' },
  { label: 'SEE_MORE', value: 'SEE_MORE' },
  { label: 'FIND_A_GROUP', value: 'FIND_A_GROUP' },
  { label: 'FIND_YOUR_GROUPS', value: 'FIND_YOUR_GROUPS' },
  { label: 'PAY_TO_ACCESS', value: 'PAY_TO_ACCESS' },
  { label: 'PURCHASE_GIFT_CARDS', value: 'PURCHASE_GIFT_CARDS' },
  { label: 'FOLLOW_PAGE', value: 'FOLLOW_PAGE' },
  { label: 'SEND_A_GIFT', value: 'SEND_A_GIFT' },
  { label: 'SWIPE_UP_SHOP', value: 'SWIPE_UP_SHOP' },
  { label: 'SWIPE_UP_PRODUCT', value: 'SWIPE_UP_PRODUCT' },
  { label: 'SEND_GIFT_MONEY', value: 'SEND_GIFT_MONEY' }
]

const creativeFormats = {
  // LINK: https://www.facebook.com/business/ads-guide/image/facebook-feed
  facebook: [
    'AUDIENCE_NETWORK_INSTREAM_VIDEO',
    'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE',
    'AUDIENCE_NETWORK_OUTSTREAM_VIDEO',
    'AUDIENCE_NETWORK_REWARDED_VIDEO',
    'DESKTOP_FEED_STANDARD',
    'FACEBOOK_STORY_MOBILE',
    'INSTAGRAM_EXPLORE_CONTEXTUAL',
    'INSTAGRAM_EXPLORE_IMMERSIVE',
    'INSTAGRAM_STANDARD',
    'INSTAGRAM_STORY',
    'INSTANT_ARTICLE_RECIRCULATION_AD',
    'INSTANT_ARTICLE_STANDARD',
    'INSTREAM_VIDEO_DESKTOP',
    'INSTREAM_VIDEO_MOBILE',
    'JOB_BROWSER_DESKTOP',
    'JOB_BROWSER_MOBILE',
    'MARKETPLACE_MOBILE',
    'MESSENGER_MOBILE_INBOX_MEDIA',
    'MESSENGER_MOBILE_STORY_MEDIA',
    'MOBILE_BANNER',
    'MOBILE_FEED_BASIC',
    'MOBILE_FEED_STANDARD',
    'MOBILE_FULLWIDTH',
    'MOBILE_INTERSTITIAL',
    'MOBILE_MEDIUM_RECTANGLE',
    'MOBILE_NATIVE',
    'RIGHT_COLUMN_STANDARD',
    'SUGGESTED_VIDEO_DESKTOP',
    'SUGGESTED_VIDEO_MOBILE',
    'WATCH_FEED_MOBILE'
  ]
}
// TODO: Create Data Model structure
// https://vuex.vuejs.org/guide/modules.html
export default new Vuex.Store({
  modules: {
    // Core module
    core: {
      state: {
        platforms: [],
        objectives: [],
        billingEvents: [],
        optimizationGoals: [],
        insights: [],
        statuses: [],
        genders: [],
        specialAdCategories: [],
        bidStrategies: [],
        callToActionValues: [],
        countries: [],
        appPath: '/signin',
        notificationData: null,
        modalData: null,
        modalVeil: false,
        loading: [],
        creativeFormats: {}
      },
      mutations: {
        [RESET_APP_STORE] (state) {
          state = {
            platforms: [],
            objectives: [],
            billingEvents: [],
            optimizationGoals: [],
            insights: [],
            statuses: [],
            genders: [],
            specialAdCategories: [],
            bidStrategies: [],
            callToActionValues: [],
            countries: [],
            appPath: '/signin',
            notificationData: null,
            modalData: null,
            modalVeil: false,
            loading: [],
            creativeFormats: {}
          }
        },
        [SET_NOTIFICATION] (state, payload) {
          state.notificationData = payload
        },
        [SET_MODAL] (state, payload) {
          state.modalData = payload
        },
        [SET_MODAL_VEIL] (state, payload) {
          state.modalVeil = payload
        },
        [SET_APP_PATH] (state, payload) {
          state.appPath = payload
        },
        [ADD_LOADING] (state, payload) {
          state.loading.push(payload)
        },
        [REMOVE_LOADING] (state, payload) {
          state.loading.splice(state.loading.indexOf(payload), 1)
        },
        [SET_PLATFORMS] (state, payload) {
          state.platforms = [...payload]
        },
        [SET_OBJECTIVES] (state, payload) {
          state.objectives = [...payload]
        },
        [SET_BILLING_EVENTS] (state, payload) {
          state.billingEvents = [...payload]
        },
        [SET_OPTIMIZATION_GOALS] (state, payload) {
          state.optimizationGoals = [...payload]
        },
        [SET_INSIGHTS] (state, payload) {
          state.insights = [...payload]
        },
        [SET_STATUSES] (state, payload) {
          state.statuses = [...payload]
        },
        [SET_GENDERS] (state, payload) {
          state.genders = [...payload]
        },
        [SET_SPECIAL_AD_CATEGORIES] (state, payload) {
          state.specialAdCategories = [...payload]
        },
        [SET_BID_STRATEGIES] (state, payload) {
          state.bidStrategies = [...payload]
        },
        [SET_PACING_TYPES] (state, payload) {
          state.pacingTypes = [...payload]
        },
        [SET_CREATIVE_FORMATS] (state, payload) {
          state.creativeFormats = { ...payload }
        },
        [SET_COUNTRIES] (state, payload) {
          state.countries = [...payload]
        },
        [SET_CALL_TO_ACTION_VALUES] (state, payload) {
          state.callToActionValues = [...payload]
        }
      },
      actions: {
        [SHOW_NOTIFICATION] ({ commit, state, rootState }, { text, type, icon }) {
          commit(SET_NOTIFICATION, { text, type, icon })
        },
        [HIDE_NOTIFICATION] ({ commit, state, rootState }) {
          commit(SET_NOTIFICATION, null)
        },
        [SHOW_MODAL] ({ dispatch, commit, state, rootState }, modalData) {
          dispatch(SHOW_MODAL_VEIL)
          commit(SET_MODAL, modalData)
        },
        [HIDE_MODAL] ({ dispatch, commit, state, rootState }) {
          dispatch(HIDE_MODAL_VEIL)
          commit(SET_MODAL, null)
        },
        [SHOW_MODAL_VEIL] ({ commit, state, rootState }, modalData) {
          commit(SET_MODAL_VEIL, true)
        },
        [HIDE_MODAL_VEIL] ({ commit, state, rootState }) {
          commit(SET_MODAL_VEIL, false)
        },
        [NAVIGATE_TO] ({ commit, state, rootState }, { appPath }) {
          commit(SET_APP_PATH, appPath)
        },
        [START_LOADING] ({ commit, state, rootState }, loading) {
          commit(ADD_LOADING, loading)
        },
        [END_LOADING] ({ commit, state, rootState }, loading) {
          commit(REMOVE_LOADING, loading)
        },
        [GET_PLATFORMS] ({ commit, state, rootState }) {
          commit(SET_PLATFORMS, platforms)
        },
        [GET_OBJECTIVES] ({ commit, state, rootState }) {
          commit(SET_OBJECTIVES, objectives)
        },
        [GET_BILLING_EVENTS] ({ commit, state, rootState }) {
          commit(SET_BILLING_EVENTS, billingEvents)
        },
        [GET_OPTIMIZATION_GOALS] ({ commit, state, rootState }) {
          commit(SET_OPTIMIZATION_GOALS, optimizationGoals)
        },
        [GET_INSIGHTS] ({ commit, state, rootState }) {
          commit(SET_INSIGHTS, insights)
        },
        [GET_STATUSES] ({ commit, state, rootState }) {
          commit(SET_STATUSES, statuses)
        },
        [GET_GENDERS] ({ commit, state, rootState }) {
          commit(SET_GENDERS, genders)
        },
        [GET_SPECIAL_AD_CATEGORIES] ({ commit, state, rootState }) {
          commit(SET_SPECIAL_AD_CATEGORIES, specialAdCategories)
        },
        [GET_BID_STRATEGIES] ({ commit, state, rootState }) {
          commit(SET_BID_STRATEGIES, bidStrategies)
        },
        [GET_PACING_TYPES] ({ commit, state, rootState }) {
          commit(SET_PACING_TYPES, pacingTypes)
        },
        [GET_CREATIVE_FORMATS] ({ commit, state, rootState }) {
          commit(SET_CREATIVE_FORMATS, creativeFormats)
        },
        [GET_CALL_TO_ACTION_VALUES] ({ commit, state, rootState }) {
          commit(SET_CALL_TO_ACTION_VALUES, callToActionValues)
        },
        [GET_COUNTRIES] ({ commit, state, rootState }) {
          commit(ADD_LOADING, GET_COUNTRIES)
          HTTP_COUNTRIES().get()
            .then(response => {
              commit(REMOVE_LOADING, SET_COUNTRIES)
              commit(SET_COUNTRIES, response.data.map(country => {
                return {
                  value: country.alpha2Code,
                  label: country.name
                }
              }))
            })
            .catch(error => {
              commit(REMOVE_LOADING, SET_COUNTRIES)
              notifyError(error, commit)
            })
        }
      },
      getters: {
        notificationData: state => state.notificationData,
        modalData: state => state.modalData,
        modalVeil: state => state.modalVeil,
        appPath: state => state.appPath,
        loading: state => state.loading,
        platforms: state => state.platforms,
        objectives: state => state.objectives,
        insights: state => state.insights,
        statuses: state => state.statuses,
        billingEvents: state => state.billingEvents,
        optimizationGoals: state => state.optimizationGoals,
        genders: state => state.genders,
        countries: state => state.countries,
        creativeFormats: state => state.creativeFormats,
        specialAdCategories: state => state.specialAdCategories,
        bidStrategies: state => state.bidStrategies,
        pacingTypes: state => state.pacingTypes,
        callToActionValues: state => state.callToActionValues
      }
    },
    dashboard: dashboardStore,
    campaign: campaignStore,
    login: loginStore,
    signup: signUpStore,
    team: teamStore,
    usersTeams: usersTeamsStore,
    profile: profileStore,
    templates: templatesStore,
    reports: reportsStore,
    controller: controllerStore
  }
})

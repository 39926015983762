// Dynamic module route
import {
  beforeSignUp
  // ,
  // beforeRouteLeave
} from './route.guard'

export default [
  {
    path: '/signup',
    alias: '/',
    name: 'signup',
    meta: {
      linkText: 'signup',
      imgSrc: '/img/baseline_exit_to_app_white_48dp.png'
    },
    beforeEnter: beforeSignUp,
    component: () => import(/* webpackChunkName: "signup" */ './SignUp.vue')
  }
]

// TODO: https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec
/*
* Axios doc: https://github.com/axios/axios
* TODO: Check this config options:
// `timeout` specifies the number of milliseconds before the request times out.
// If the request takes longer than `timeout`, the request will be aborted.
timeout: 1000, // default is `0` (no timeout)

// `onUploadProgress` allows handling of progress events for uploads
onUploadProgress: function (progressEvent) {
  // Do whatever you want with the native progress event
},

// `onDownloadProgress` allows handling of progress events for downloads
onDownloadProgress: function (progressEvent) {
  // Do whatever you want with the native progress event
},
*/
// this is beta
import axios from 'axios'
const apiUrl = process.env.VUE_APP_API
const apis = {
  login: `${apiUrl}/login`,
  logout: `${apiUrl}/logout`,
  signup: `${apiUrl}/signup`,
  resetPassword: `${apiUrl}/password/email`,
  changePassword: `${apiUrl}/password/reset`,
  user: `${apiUrl}/user`,
  users: `${apiUrl}/users`,
  permissions: `${apiUrl}/permissions`,
  teams: `${apiUrl}/teams`,
  dashboard: `${apiUrl}/dashboard`,
  // campaigns: `${apiUrl}/campaigns_billing`,
  campaigns: `${apiUrl}/campaigns`,
  activeCampaigns: `${apiUrl}/activecampaigns`,
  campaign: `${apiUrl}/campaigns`,
  campaignsSearch: `${apiUrl}/campaigns/search`,
  lineItems: `${apiUrl}/line_items`,
  adsets: `${apiUrl}/adsets`,
  ads: `${apiUrl}/ads`,
  media: `${apiUrl}/media`,
  accounts: `${apiUrl}/accounts`,
  orders: `${apiUrl}/orders`,
  ordersSearch: `${apiUrl}/orders/search`,
  oauth: {
    facebook: `${apiUrl}/oauth/facebook`,
    facebookCallback: `${apiUrl}/oauth/facebook/callback`,
    facebookLogout: `${apiUrl}/oauth/facebook/logout`,
    twitter: `${apiUrl}/oauth/twitter`,
    twitterCallback: `${apiUrl}/oauth/twitter/callback`,
    twitterLogout: `${apiUrl}/oauth/twitter/logout`
  },
  countries: `${apiUrl}/countries`,
  targeting: {
    facebook: `${apiUrl}/targeting/facebook`
  },
  reporting: {
    templates: `${apiUrl}/templates`,
    reports: `${apiUrl}/reports`
  },
  pages: `${apiUrl}/pages`,
  instagram: `${apiUrl}/instagrams`,
  videos: `${apiUrl}/videos`,
  estimatedReach: {
    // facebook: `${apiUrl}/estimated_reach/1`
    facebook: `${apiUrl}/estimated_reach/`
  },
  controllerOrders: `${apiUrl}/controller`,
  callToAction: {
    facebook: `${apiUrl}/calltoaction`
  }
}
const getAuthToken = () => `Bearer ${localStorage.getItem('access_token')}`
const createSimpleAxiosInstance = (baseURL) => {
  return axios.create({ baseURL })
}
const createAxiosInstance = (baseURL, isFileUpload = false) => {
  let instance
  if (!isFileUpload) {
    instance = axios.create({
      baseURL,
      headers: {
        Authorization: getAuthToken()
      }
    })
  } else {
    // TODO: Keep in mind that, due to the fact that we are dealing with files on these endpoints, all POST requests should be encoded using multipart/form-data encoding.
    // READ: https://serversideup.net/uploading-files-vuejs-axios/
    instance = axios.create({
      baseURL,
      headers: {
        Authorization: getAuthToken(),
        'content-type': 'multipart/form-data'
      }
      // ,
      // transformRequest: [
      //   function (data, headers) {
      //     // Modify data, JSON -> FormData
      //     console.log('REQUEST', data)
      //     return data
      //   }
      // ]
    })
  }

  return instance
}
export const API_URL = apiUrl
export const HTTP_SIGNIN = createAxiosInstance.bind(this, apis.login)
export const HTTP_SIGNOUT = createAxiosInstance.bind(this, apis.logout)
export const HTTP_SIGNUP = createAxiosInstance.bind(this, apis.signup)
export const HTTP_RESET_PASSWORD = createAxiosInstance.bind(this, apis.resetPassword)
export const HTTP_CHANGE_PASSWORD = createAxiosInstance.bind(this, apis.changePassword)
export const HTTP_PERMISSIONS = createAxiosInstance.bind(this, apis.permissions)
export const HTTP_USERDATA = createAxiosInstance.bind(this, apis.user)
export const HTTP_USERSDATA = createAxiosInstance.bind(this, apis.users)
export const HTTP_TEAMSDATA = createAxiosInstance.bind(this, apis.teams)
export const HTTP_CAMPAIGNS = createAxiosInstance.bind(this, apis.campaigns)
export const HTTP_ACTIVE_CAMPAIGNS = createAxiosInstance.bind(this, apis.activeCampaigns)
export const HTTP_CAMPAIGNS_SEARCH = createAxiosInstance.bind(this, apis.campaignsSearch)
export const HTTP_LINE_ITEMS = createAxiosInstance.bind(this, apis.lineItems)
export const HTTP_CAMPAIGN = createAxiosInstance.bind(this, apis.campaign)
export const HTTP_ADSETS = createAxiosInstance.bind(this, apis.adsets)
export const HTTP_ADS = createAxiosInstance.bind(this, apis.ads)
export const HTTP_CREATIVE = createAxiosInstance.bind(this, apis.media, true)// FILE UPLOAD!
export const HTTP_VIDEOS = createAxiosInstance.bind(this, apis.videos, true)// FILE UPLOAD!
export const HTTP_ACCOUNTS = createAxiosInstance.bind(this, apis.accounts)
export const HTTP_ORDERS = createAxiosInstance.bind(this, apis.orders)
export const HTTP_ORDERS_SEARCH = createAxiosInstance.bind(this, apis.ordersSearch)
export const HTTP_OAUTH_FACEBOOK = createAxiosInstance.bind(this, apis.oauth.facebook)
export const HTTP_OAUTH_FACEBOOK_CALLBACK = createAxiosInstance.bind(this, apis.oauth.facebookCallback)
export const HTTP_OAUTH_FACEBOOK_LOGOUT = createAxiosInstance.bind(this, apis.oauth.facebookLogout)
export const HTTP_OAUTH_TWITTER = createAxiosInstance.bind(this, apis.oauth.twitter)
export const HTTP_OAUTH_TWITTER_CALLBACK = createAxiosInstance.bind(this, apis.oauth.twitterCallback)
export const HTTP_OAUTH_TWITTER_LOGOUT = createAxiosInstance.bind(this, apis.oauth.twitterLogout)
export const HTTP_COUNTRIES = createSimpleAxiosInstance.bind(this, apis.countries)
export const HTTP_TARGETING_FACEBOOK = createAxiosInstance.bind(this, apis.targeting.facebook)
export const HTTP_REPORTING_TEMPLATES = createAxiosInstance.bind(this, apis.reporting.templates)
export const HTTP_REPORTING_REPORTS = createAxiosInstance.bind(this, apis.reporting.reports)
export const HTTP_PAGES = createAxiosInstance.bind(this, apis.pages)
export const HTTP_INSTAGRAM_PAGES = createAxiosInstance.bind(this, apis.instagram)
export const HTTP_FB_ESTIMATED_REACH = createAxiosInstance.bind(this, apis.estimatedReach.facebook)
export const HTTP_CONTROLLER_ORDERS = createAxiosInstance.bind(this, apis.controllerOrders)
export const HTTP_FB_CALL_TO_ACTION = createAxiosInstance.bind(this, apis.callToAction.facebook)

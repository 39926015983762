// Dynamic module route
import { beforeReporting } from './route.guard'

export default [
  {
    path: '/campaign/',
    name: 'campaign',
    meta: {
      requiresAuth: true,
      linkText: 'campaign',
      imgSrc: '/img/baseline_my_location_white_48dp.png'
    },
    beforeEnter: beforeReporting,
    component: () => import(/* webpackChunkName: "campaign" */ './Campaign.vue')
  },
  {
    path: '/campaign/:id/adset',
    name: 'campaign-adset',
    meta: {
      requiresAuth: true,
      linkText: 'adset',
      imgSrc: '/img/baseline_my_location_white_48dp.png'
    },
    beforeEnter: beforeReporting,
    component: () => import(/* webpackChunkName: "campaign" */ './Adset.vue')
  },
  {
    path: '/campaign/:id/adset/:adsetid/ad',
    name: 'campaign-adset-ad',
    meta: {
      requiresAuth: true,
      linkText: 'ad',
      imgSrc: '/img/baseline_my_location_white_48dp.png'
    },
    beforeEnter: beforeReporting,
    component: () => import(/* webpackChunkName: "campaign" */ './Ad.vue')
  },
  {
    path: '/campaign/:id/summary',
    name: 'campaign-summary',
    meta: {
      requiresAuth: true,
      linkText: 'ad',
      imgSrc: '/img/baseline_my_location_white_48dp.png'
    },
    beforeEnter: beforeReporting,
    component: () => import(/* webpackChunkName: "campaign" */ './Summary.vue')
  }
]

// Dynamic module route
import { beforeReports } from './route.guard'

export default [
  {
    path: '/reports',
    name: 'reports',
    meta: {
      requiresAuth: true,
      linkText: 'reports',
      imgSrc: '/img/baseline_assessment_white_48dp.png'
    },
    beforeEnter: beforeReports,
    component: () => import(/* webpackChunkName: "reports" */ './Reports.vue')
  },
  {
    path: '/reports/new',
    name: 'reports-new',
    meta: {
      requiresAuth: true,
      linkText: 'reports',
      imgSrc: '/img/baseline_assessment_white_48dp.png'
    },
    beforeEnter: beforeReports,
    component: () => import(/* webpackChunkName: "reports" */ './NewReport.vue')
  }
]

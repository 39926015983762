// Module route
import NonExisting from './NonExisting.vue'

export default [
  {
    path: '*',
    name: 'non-existing',
    component: NonExisting
  }
]
